import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./CsrPage.css"
export default function CsrPage() {
    
    return (
        <>
            <section id="donate-inner-section" className="donate-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Join as CSR</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row event-right">
                        <div className="col-12 text-center">
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Be a CSR
                                </h1>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                Make a Difference in the Lives of Visually Impaired Individuals 
                                </p>

                            </div>

                        </div>
                        <div className="col-12">
                            <p>
                                We invite corporations and businesses to join us in our mission to empower and support the visually impaired community in Delhi.
                            </p>
                            <p>
                                At NAB Delhi, we have been providing services and support to visually impaired individuals for over 40 years. We believe that every individual, regardless of their disability, deserves the opportunity to lead a fulfilling and independent life. Our services include education, training, employment, and rehabilitation programmes, which are all geared towards helping visually impaired individuals achieve their full potential.
                            </p>
                            <p>
                                We understand that corporate social responsibility is an essential part of any business's success. It not only benefits the community but also has a positive impact on the organization itself. We invite businesses and corporations to partner with us and become a part of our efforts to create a better and more inclusive society.
                            </p>
                            <p>
                                There are many ways in which your organization can help us. You can make a financial contribution, sponsor our programmes, or donate equipment and assistive devices. Your organization can also participate in our volunteer programmes and help us spread awareness about the importance of empowering visually impaired individuals.
                            </p>
                            <p>
                                By partnering with NAB Delhi, your organization can make a positive impact on the lives of visually impaired individuals in Delhi. Your support can help us expand our services and reach out to more people who need our help.
                            </p>
                            <p>
                                If you are interested in becoming a CSR partner or would like to learn more about our programmes and services, please do not hesitate to contact us. Together, we can create a more inclusive and empowering society for all. Thank you for considering partnering with us.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
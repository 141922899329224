import React, { useState, useEffect } from 'react';
import "./NewsLetterSection.css";
import Notiflix from 'notiflix';
import PostApiCall from '../../../PostApi';
export default function NewsLetterSection() {
    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [Enquiry, setEnquiry] = useState({
        EmailRegex:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    })
    useEffect(() => {
        Notiflix.Notify.init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            fontSize: "15px",
            useFontAwesome: false,
            timeout: 4000,
        });
      }, []);
    function onSaveNewsLetterSubscriber() {
        if (name != "") {
            if (email != "") {
                if (
                    Enquiry.EmailRegex.test(email)
                ) {
                    Notiflix.Loading.arrows("Please wait...");
                    PostApiCall.postRequest({
                        id: null,
                        name: name,
                        email: email
                    },
                        "AddNewsLetterSubscriber")
                        .then((result) => result.json().then((obj) => {
                            if (result.status == 200 || result.status == 201) {

                                PostApiCall.postRequest({
                                    id: null,
                                    name: name,
                                    email: email
                                },
                                    "AddNewsLetterSubscriberClient")
                                    .then((result) => result.json().then((obj) => {
                                        if (result.status == 200 || result.status == 201) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success("Newsletter Information Submitted Successfully")
                                            SetName("")
                                            SetEmail("")
                                        }
                                    }))
                            }
                            else {
                                Notiflix.Loading.remove();
                                Notiflix.Notify.failure("Error Occured!");
                            }
                        }))


                }
                else {
                    Notiflix.Notify.failure(
                        "Please enter valid email."
                    );
                }
            } else {
                Notiflix.Notify.failure(
                    "Please enter email address."
                );
            }
        }
        else {
            Notiflix.Notify.failure("Please enter name");
        }
    }
    return (
        <>
            <section className='newsletter-section'>
                <div className='container'>
                    <div className='row justify-content-lg-end'>
                        <div className='col-lg-4 px-4 px-lg-0'>
                            <div className='newsletter-box'>
                                {/* <div className='news-icon ps-0'>
                                    <HiOutlineMailOpen />
                                </div> */}
                                <div className='text-start'>
                                    <h2>Subscribe To Our Newsletter</h2>
                                </div>
                                <div className='news-input px-0'>
                                    <div className='d-flex'>
                                        <label aria-label='Name' for="name">Name <span>*</span></label>
                                        <input id='name' type='text' aria-label='Name' value={name} onChange={(e) => {
                                            SetName(e.target.value);
                                        }} />
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <label for="email" aria-label='Email'>Email <span>*</span></label>
                                        <input id='email' type='email' aria-label='Email' value={email} onChange={(e) => {
                                            SetEmail(e.target.value);
                                        }} />
                                    </div>
                                    <div className='mt-3 text-center'>
                                        <button alt="Subscribe to NAB Delhi newsletter" className='default-btn' onClick={onSaveNewsLetterSubscriber}>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}


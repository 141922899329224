import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Notiflix from "notiflix";
import GetApiCall from "../GETAPI";

function VideoGalleryPage() {
  const [allVideoGalleryData, setAllVideoGalleryData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetVideoGalleryData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllVideoGalleryData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setAllVideoGalleryData([]);
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="video-gallery-inner-section"
        className="video-gallery-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Video Gallery</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className=" main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Youtube Videos
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Dive into Our YouTube Video Gallery
                </p>
              </div>
            </div>

            <div className="container">
              <div className="row">
                {allVideoGalleryData.map((videos, i) => {
                  return (
                    <div className="col-md-4 video-section">
                      <iframe
                        src={videos.fld_video_link}
                        title={videos.fld_title}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VideoGalleryPage;

import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PostApiCall from "../../../PostApi";
import Notiflix from "notiflix";
import Navbar from "../../Navbar/Navbar";
import { BiCommentDetail } from "react-icons/bi";
import { BsFillReplyFill } from "react-icons/bs";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import "./Blog.css";

// import AddNewComment from "./NewComment";
// skeleton
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
// import "../Skeleton.css";
import { useParams } from "react-router-dom";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${"" /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 90px 5% 0 5%;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;

const NewsBlogDetail = () => {
  let params = useParams();
  const [image, setImage] = useState("");
  const [imagealt, setImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [updatedon, setUpdatedOn] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [writtenby, setWrittenBy] = useState("");

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${
          params.id != null || params.id != undefined ? params.id : ""
        }`,
      },
      "GetNewsBlogsById"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          Notiflix.Loading.remove();
          setTitle(obj.data[0].fld_title);
          setUpdatedOn(
            obj.data[0].fld_updated_on == null
              ? moment(obj.data[0].fld_created_on).format("DD MM YYYY")
              : moment(obj.data[0].fld_updated_on).format("DD MM YYYY")
          );
          setShortDescription(obj.data[0].fld_short_description);
          setLongDescription(obj.data[0].fld_content);
          setImage(obj.data[0].fld_image);
          setImageAlt(obj.data[0].fld_image_alt);
          setWrittenBy(obj.data[0].fld_written_by);
        } else {
          Notiflix.Loading.remove();
          setTitle("");
          setUpdatedOn("");
          setShortDescription("");
          setLongDescription("");
          setImage("");
          setImageAlt("");
          setWrittenBy("");
        }
      });
    });
  }, []);

  // static contextType = store;
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     BlogData: [],
  //     done: true,
  //     BlogCommentsData: [],
  //     showCommentArea: false,
  //     comment: "",
  //     commentid: null,
  //     like: null,
  //     like: "",
  //     replyComment: false,
  //     loading: true,
  //   };
  // }
  //   useEffect(() => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });

  //     var blogid = localStorage.getItem("blogid");
  //     var blogdetailsid = JSON.parse(blogid);
  //     PostApiCall.postRequest({
  //       whereClause: `where fld_id=${blogdetailsid}`
  //     }, "GetBlogById")
  //       .then(result => result.json())
  //       .then(obj =>
  //         setBlogData(obj.data)

  //       )
  //   }, [])
  // componentDidMount() {
  //   const store = this.context;
  //   // PostApiCall.postRequest(
  //   //   {
  //   //     // whereClause: ` where fld_id = ${JSON.parse(localStorage.getItem("blogId"))}`
  //   //     whereClause: ` where fld_id = "GetNewsById"`
  //   //   }, "GetBlogArticleById").then(
  //   //     (results) => {
  //   //       results.json().then((obj) => {
  //   //         if (results.status == 200 || results.status == 201) {

  //   //           this.setState({
  //   //             BlogData: obj.data,
  //   //             loading: false,
  //   //           });
  //   //         }
  //   //       });
  //   //     }
  //   //   );
  //   this.getBlogComments()
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });

  //   var blogid = localStorage.getItem("blogid");
  //   var blogdetailsid = JSON.parse(blogid);
  //   PostApiCall.postRequest({
  //     whereClause: `where fld_id=${blogdetailsid}`
  //   }, "GetBlogById")
  //     .then(result => result.json())
  //     .then(obj =>
  //       this.setState({
  //         BlogData: obj.data
  //       })
  //     )
  // }
  // getBlogComments = () => {
  //   PostApiCall.postRequest({
  //     blogid: JSON.parse(localStorage.getItem("blogId"))
  //   }, "GetBlogComments").then(
  //     (results) => {
  //       results.json().then((obj) => {
  //         if (results.status == 200 || results.status == 201) {

  //           this.setState({
  //             BlogCommentsData: obj.data,
  //             comment: ""
  //           });
  //         }
  //       });
  //     }
  //   );
  // }

  // const rowSkeletons = 1;
  // if (this.state.loading) {
  //   let rows = [];
  //   for (let index = 0; index < rowSkeletons; index++) {
  //     rows.push(
  //       <section className=' my-4'>
  //         <Skeleton height={400} />
  //       </section>
  //     );
  //   }
  //   const rowSkeletons1 = 6;
  //   if (this.state.loading) {
  //     let rows11 = [];
  //     for (let i = 0; i < rowSkeletons1; i++) {
  //       rows11.push(
  //         <div className=' mb-1'>
  //           <Skeleton height={100} />
  //         </div>
  //       );
  //     }

  //     return (
  //       <>
  //         <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
  //           <Container>
  //             <GalleryStyles className="gallery__grid">
  //               <div className="">{rows}</div>
  //               <div className="">{rows11}</div>
  //             </GalleryStyles>
  //           </Container>
  //         </SkeletonTheme>
  //       </>
  //     );
  //   }
  // }
  return (
    <section
      id="about-inner-section"
      className="about-inner-section inner-banner"
    >
      <Navbar />
      <div className="page-title-area">
        <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
          <div className="page-title-content">
            {/* <h2>About Us</h2>
                            <h3>???</h3> */}
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>News & Blogs</li>
            </ul>
          </div>
        </div>
      </div>
      <Container fluid className="px-0 blogdetailoverflow mt-4">
        <Container className="mt-4">
          <Row>
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  News & Blogs
                </h1>
              </div>
            </div>
            <div>
              <div className="col-12 my-4 blog-image">
                <img className="imgblogcontent" src={image} alt={imagealt} />
              </div>
              <Col lg={12} className="blog_heading">
                <h4>{title}</h4>
              </Col>
              <Row className="d-flex justify-content-between">
                <Col lg={6} className="">
                  <h6 className="blogdetailsdatefont1">
                    Updated on - {updatedon}
                  </h6>
                  <h6 className="blogdetailsdatefont1">
                    Posted by -<span className="mx-2">{writtenby}</span>
                  </h6>
                </Col>
                {/* <Col
                lg={6}
                className="blogdetail-socialicon float-right justify-content-end text-lg-end text-left social-medialink"
              >
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthexcoach.com%2Farticle%2F&amp"
                  target="_blank"
                  style={{ fontSize: "30px" }}
                >
                  <FaFacebookSquare className="blogdeatilicon" />
                </a>
                <a
                  href="https://www.linkedin.com/shareArticle?mini=true&summary=youtube&title=f1&url=https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                  className="ps-4"
                  target="_blank"
                  style={{ fontSize: "30px" }}
                >
                  <FaLinkedin className="blogdeatilicon" />
                </a>
                <a
                  href="http://www.twitter.com/share?url=http://mysite.org/foo.htm?bar=123&baz=456"
                  className="ps-4"
                  target="_blank"
                  style={{ fontSize: "30px" }}
                >
                  <FaTwitterSquare className="blogdeatilicon" />
                </a>
                <a
                  href="https://www.instagram.com/send?text=https://meluhahome.in/blog"
                  className="ps-4"
                  target="_blank"
                  style={{ fontSize: "30px" }}
                >
                  <FaInstagramSquare className="blogdeatilicon" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?text=https://meluhahome.in/blog"
                  data-action="share/whatsapp/share"
                  className="ps-4"
                  style={{ fontSize: "30px" }}
                  target="_blank"
                >
                  <FaWhatsappSquare className="blogdeatilicon" />
                </a>
              </Col> */}
              </Row>
              <div className="col-12">
                <h3 mt-2>Short Description</h3>
                <p>{shortdescription}</p>
                <h3 mt-2>Long Description</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(longdescription),
                  }}
                />
              </div>
            </div>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

export default NewsBlogDetail;

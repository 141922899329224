import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import PostApiCall from "../PostApi";
import GetApiCall from "../GETAPI";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";

function AlbumGalleryPage() {
  let params = useParams();
  const [allgalleryimages, setAllGalleryImages] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_album_id=${params.albumid}`,
      },
      "GetImageGalleryImages"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj1.data.length != 0) {
            setAllGalleryImages(obj1.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Notify.failure("No Data Exists");
            setAllGalleryImages([]);
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }, []);

  return (
    <>
      <section
        id="photo-gallery-inner-section"
        className="photo-gallery-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Photo Gallery</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className=" main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Gallery
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Capturing The Frames of Inspiration
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 my-5">
          <div className="all-service-card">
            <div className="as-card d-flex align-items-center">
              <div className="as-img as-gallery">
                {allgalleryimages.map((images, i) => {
                  return (
                    <img
                      src={images.fld_image}
                      alt={images.fld_image_alt}
                      className="img-fluid"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AlbumGalleryPage;

import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./404Page.css";
import NotFoundImg from "../../assets/images/404.svg";
export default function NotFoundPage() {
  return (
    <>
      <section
        id="NotFoundPage-inner-section"
        className="NotFoundPage-inner-section mb-0 inner-banner">
        <Navbar />
        {/* <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About Us</li>
                                <li>Disclaimer</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
        <div className="container pt-lg-5 pb-3 about-organisation">
          <div className="row mt-lg-4">
            <div className="col-12 mt-lg-4 d-flex flex-column justify-content-center">
              <img src={NotFoundImg} className="img-fluid not-found-img" />
                <a href="/" class="default-btn">
                  Go To Home Page
                </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
// import "./ProjectPage.css";
import Navbar from "../Navbar/Navbar";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import GetApiCall from "../../GETAPI";
import { useEffect } from "react";
import { useState } from "react";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import "./ProjectPage.css";
export default function ProjectOngoingPage() {
  const [allProjectsData, setAllprojectsData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_project_category='Ongoing Projects'`,
      },
      "GetProjectByCategory"
    ).then((results1) =>
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj.data.length != 0) {
            var activeobjdata = [];
            obj.data.map((objdata, i) => {
              if (objdata.fld_status === "Active") {
                activeobjdata.push(objdata);
              }
            });
            setAllprojectsData(activeobjdata);
            Notiflix.Loading.remove();
          }
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  return (
    <>
      <section
        id="completed-project-inner-section"
        className="completed-project-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Projects</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="text-center col-12 main-heading">
              <h1 className="main-title wow animate__animated animate__fadeInUp">
                Projects
              </h1>
              <p className="main-text wow animate__animated animate__fadeInUp">
                Our Portfolio of Projects
              </p>
            </div>
          </div>
          <div className="row">
            {allProjectsData.map((projects, i) => {
              return (
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="project-card">
                    <a
                      href={`/ongoing-projects/${projects.fld_project_name.replace(
                        / /g,
                        "-"
                      )}/${projects.fld_id}`}>
                      <div>
                        <img
                          src={projects.fld_cover_image}
                          className="img-fluid"
                          alt={projects.fld_cover_image_alt}
                        />
                      </div>
                    </a>
                    <div className="project-content p-3">
                      {/* <a href="/ongoing-projects" alt="Project " aria-label="Project "><HiChevronRight /></a> */}

                      <h3>
                        <a
                          href={`/ongoing-projects/${projects.fld_project_name.replace(
                            / /g,
                            "-"
                          )}/${projects.fld_id}`}>
                          {projects.fld_project_name}
                        </a>
                      </h3>
                      <p>{projects.fld_short_description}</p>
                      {/* <span className="fw-bold">{projects.fld_project_category
                    }</span> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

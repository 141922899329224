import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import ImageGallrey from "../components/PhotoGallery/PhotoGallery";
import Notiflix from "notiflix";
import GetApiCall from "../GETAPI";

function PhotoGallery() {
  const [allGalleryAlbum, setAllGalleryAlbum] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetImageGalleryAlbumData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllGalleryAlbum(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          setAllGalleryAlbum([]);
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="photo-gallery-inner-section"
        className="photo-gallery-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Photo Gallery</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 my-5">
              <div className="all-service-card">
                <div className="main-heading main-heading-left">
                  <h1 className="main-text wow animate__animated animate__fadeInUp">
                    Gallery
                  </h1>
                </div>
                <div className="row as-card">
                  <div className="as-img as-gallery">
                    {allGalleryAlbum.map((images, i) => {
                      return (
                        <div className="col-md-3 px-2">
                          <a
                            className="video-card d-flex flex-column"
                            href={`album-gallery/${images.fld_id}`}
                          >
                            <img
                              src={images.fld_image}
                              alt={images.fld_image_alt}
                              className="img-fluid"
                            />
                            <span className="form-field mt-2">
                              {images.fld_album_name}
                            </span>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PhotoGallery;

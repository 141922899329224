import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import "./AcademyPage.css";
import Notiflix from "notiflix";
import GetApiCall from "../GETAPI";
import createDOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

export default function AcademyPage() {
  const [getAcademy, setAllAcademy] = useState([]);
  const [innerAcademyData, setInnerAcademyData] = useState([]);
  const DOMPurify = createDOMPurify(window);
  const navigate = useNavigate();
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetAcademyData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status == "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllAcademy(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setAllAcademy([]);
        }
      });
    });

    // **** Inner Academy Data ****
    GetApiCall.getRequest("GetInnerAcademyData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status == "Active") {
              activeobjdata.push(objdata);
            }
          });
          setInnerAcademyData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setInnerAcademyData([]);
        }
      });
    });
  }, []);

  const onReadMore = (academyData) => {
    const url = academyData?.fld_title.toLowerCase().replace(/\s+/g, "-");
    navigate(`/academy/${url}`, { state: { data: academyData } });
  };

  return (
    <>
      <section
        id="terms-conditions-inner-section"
        className="terms-conditions-inner-section inner-banner mb-4">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Academy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {getAcademy.length == 0 ? (
        <p className="text-center my-5 fs-4">No Data Found</p>
      ) : (
        <>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 academy-image">
                  <img
                    className="img-fluid"
                    src={getAcademy[0]?.fld_coverimage}
                    alt={getAcademy[0]?.fld_coverimagealt}></img>{" "}
                </div>
                <div className="col-lg-8 mt-lg-0 mt-3">
                  {getAcademy.length > 0 && (
                    <>
                      <h2 class="wow animate__animated animate__fadeInUp main-heading main-heading-left mb-4">
                        {getAcademy[0].fld_title}
                      </h2>
                      <p>{getAcademy[0].fld_shortdescription}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="mb-3 mt-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        getAcademy[0]?.fld_longdescription
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="mb-5">
            <div className="container">
              <div className="row">
                {innerAcademyData.length != 0 ? (
                  <div className="col-lg-12 mb-3">
                    <h2 class="wow animate__animated animate__fadeInUp main-heading main-heading-left mb-2">
                      Available Courses
                    </h2>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  {innerAcademyData?.map((data) => {
                    return (
                      <div className="col-lg-3 mt-lg-3 mb-lg-0 mb-3">
                        <div className="academy-card">
                          <a
                            href={`/academy/${data.fld_title
                              .trim()
                              .replace(/\.+$/, "")
                              .replace(/\s+/g, "-")
                              .toLowerCase()}/${data.fld_id}`}
                            // aria-label={data.fld_title}
                          >
                            <img
                              className="img-fluid"
                              src={data?.fld_coverimage}
                              alt="Image"></img>
                          </a>
                          <div className="academy-content">
                            <div>
                              <h4>
                                <a
                                  href={`/academy/${data.fld_title
                                    .trim()
                                    .replace(/\.+$/, "")
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()}/${data.fld_id}`}
                                  aria-label={data.fld_title}>
                                  {data.fld_title}
                                </a>
                              </h4>
                              {data?.fld_shortdescription && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      data?.fld_shortdescription
                                    ),
                                  }}
                                />
                              )}
                            </div>
                            <a
                              href={`/academy/${data.fld_title
                                .trim()
                                .replace(/\.+$/, "")
                                .replace(/\s+/g, "-")
                                .toLowerCase()}/${data.fld_id}`}
                              aria-label={`Read More ${data.fld_title}`}
                              className="nav-link nav-link-btn default-btn nav-link page-dummy ">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

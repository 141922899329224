import React from 'react'
import "./HomeBanner.css";
import Navbar from "../../Navbar/Navbar";


function HomeBanner() {
  return (
    <>
      <section className='Main-slider d-lg-flex justify-content-lg-center align-items-lg-center position-relative'>
        <Navbar />
        <div className='container mt-auto mb-4'>
          <div className='row justify-content-lg-center align-items-end'>
            <div className='col-lg-8 text-center'>
              <div className='home-banner text-center'>
                {/* <p className='b-h1'>
                  We are Making a Difference !
                </p>
                <p className='b-h2'>
                  Alone we can do so little,
                  together we can do so much.
                </p>
                <p>~ Hellen Keller</p>
                <p>
                  NAB Delhi’s prime objective has been to make visually impaired persons both self-reliant and contributing members of the society.
                </p>
                <ul className='d-lg-flex justify-content-between ps-0'>
                  <li>
                    <span> 80,000</span>
                    <span> Beneficiaries</span>
                  </li>
                  <li>
                    <span>5,000 </span>
                    <span>Books Converted to ePUB</span>
                  </li>
                  <li>
                    <span>
                      7,000 </span>
                    <span>Made Digitally Literate</span>

                  </li>
                </ul> */}
                <p className='b-h2'>
                  National
                  Association For
                  The Blind
                  <br />
                  (NAB DELHI)
                </p>
                <p className='b-h1'>
                  80G | FCRA | CSR Preferred Partner | USA 501(c) (3) tax exemption | Guide Star
                  Certified
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default HomeBanner;
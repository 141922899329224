import React from "react";
import "./HistoryAchievements.css";
import Navbar from "../../Navbar/Navbar";
import ImgLeft from "../../../assets/images/banner/event.png";
export default function HistoryAchievements() {

    return (
        <>
            <section id="vision-misson" className="vision-misson inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About Us</li>
                                <li>History & Achievements</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    History & Achievements of NAB Delhi
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi’s prime objective has been to make visually impaired persons both self-reliant and contributing members of the society.

                                    
                                </p> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 mb-4 mb-lg-0 text-center text-lg-start">
                            <div className="">
                                 <img className="img-fluid" src={ImgLeft}></img> 
                            </div>
                        </div>
                        <div className="col-lg-8">
                            
                            <div className="vm-right">

                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Vision
                                </h2>

                                <p>
                                    To see the visually impaired population of India as empowered and
                                    well-informed  individuals  working  towards  personal  growth  and
                                    development thereby leading life to its optimum potential
                                </p>
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Mission
                                </h2>
                                <p>
                                    To empower persons with Visual Impairment with education and
                                    training to attain self-sufficiency through employment self-
                                    employment thus, integrating them with dignity in mainstream society.
                                    To prevent blindness as much as possible.
                                </p>
                                {/* <div className="vr-top">
                                    <h4>Vision</h4>
                                    <p>

                                    </p>
                                </div> 
                                
                            </div>
                        </div>
                        <div className="col-md-6 mt-5">
                        <div className="vm-left-content">
                                    <div className="main-heading main-heading-left">
                                        <h2 className="main-title wow animate__animated animate__fadeInUp">
                                            Our Philosophy
                                        </h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <p>
                                                Create opportunities with accountability for the blind.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Provide maximum information to the blind using all modes
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Be sensitive while dealing with the blind
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Network   with   other   organizations   working   in   the   field   of   all
                                                disabilities.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                        <div className="col-md-6 mt-5">
                        <div className="vm-left-content">
                                    <div className="main-heading main-heading-left">
                                        <h2 className="main-title wow animate__animated animate__fadeInUp">
                                            Values
                                        </h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <p>
                                                Create opportunities with accountability for the blind.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Provide maximum information to the blind using all modes
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Be sensitive while dealing with the blind
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Network   with   other   organizations   working   in   the   field   of   all
                                                disabilities.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                        </div> */}

                        <div className="col-12">
                            <p>
                                <b> National Association for the Blind, Delhi State Branch</b>; was established on 19th April, 1979. It was registerd under the Societies Registration Act as a Charitable Society. NAB Delhi is part of a large network of 65 organizations all over the country which share the same name “National Asociation for the Blind”. The NAB family of organizations believe in inclusion and collaborate  with each other on many advocacy and program issues. However, NAB Delhi is a separate legal entity and has independent management.
                            </p>
                            <p>
                                In the initial years Late Vidya Batra and Late Shri Jagdish Kapur played a pivotal role in setting up the organization. It is due to their efforts that NAB Delhi at this time is a well established prominent NGO that has already impacted the lives of 1,00,000 visually impaired persons.
                            </p>
                            <p>
                                NAB Delhi took up activities like teaching mobility, daily living skills and primary education to visually impaired children in the beginning.
                            </p>
                            <p>
                                The organization became more active since 1986 when it received land from the government and was able to construct hostel and classrooms. For meeting the educational needs of blind children, it started a preparatory school for the blind children in the age group of 4 to 8 years. In the Preparatory unit now known as Pre-Integration unit the children are training in communication skills, activities of daily living, environmental information and object perception, orientation and mobility, physical exercises and recreation.
                            </p>
                            <p>
                                <b> Integrated Education</b> was initiated in late 1980’s. Since then blind children who complete their training in the Preparatory unit are sent to best private and governmental schools in South Delhi region. Since then every year more than 250 children are benefitting of this unique quality inclusive school education program conceptualized and established by NAB Delhi.
                            </p>
                            <p>
                                <b> Centre for Multiple Disabilities (earlier known as Multi-Handicapped Unit)</b> was initiated in the year        1998 with          10 children with multiple disabilities and            3 special educators. This unit focuses on the developmental, social, educational, therapeutic and other rehabilitation needs of such children.
                            </p>
                            <p>
                                NAB Delhi received national and international acclaim for its pioneering work as the organization spread its wings.
                            </p>
                            <p>
                                <b> Introduced computer training for the blind in India</b>: NAB established the most advanced computer training centre for persons with vision impairment way back in 1994. The sight of a blind person working independently at the computer was like magic to most and a dream coming true to visually impaired persons. Since 1994 this centre has trained thousands of persons who benefitted immensely by way of being able to take up professional courses of study like MBA and MCA and get excellent jobs in companies like IBM and Wipro.
                            </p>
                            <p>
                                <b> Brought the digital talking book (DAISY) revolution to South Asia in 2002</b>: NAB Delhi established the first digital book production facility and resource centre in South Asia. This Resource Centre also facilitated the establishment of digital talking book production and distribution centres in many South Asian countries including Sri Lanka, Bangladesh, Nepal and Pakistan.
                            </p>
                            <p>
                                <b> Evolved and established the concept of Integrated Education for the blind in India</b>: Now the Integrated system of education has been adopted by the Government and many organizations for and of the blind.
                            </p>
                            <p>
                                <b> Development of Indian language screen reading software</b>: Much after NAB initiated computer training for the blind, the facility to read and write on the computer remained restricted to English language. Visually impaired persons who had no English knowledge and which constitute majority of the VI population in the country remained untouched with this revolution. Realizing this NAB Delhi started a project for development of Screen Reading software for Hindi and other Indian languages around 2005. This project named SAFA was later supported by Media Lab Asia (a Govt. company) and resulted in release of fully functional software that is being used for reading and writing Hindi by thousands in the country.
                            </p>
                            <p>
                                <b> NAB Delhi has been a catalyst in the establishment of almost all the ICT centres for the visually impaired which have come up in India.</b> NAB staff has trained the Trainers and provided consultancy and helped in the installation of assistive technology at these centres.
                            </p>
                            <p>
                                <b>
                                    NAB has contributed in the establishment of 120 modern ICT labs for persons with disabilities at small towns covering all states of India under the   ERNET (Education & Research Network), Government of India project during 2005 to 2007.

                                </b>
                            </p>
                        </div>
                        <div className="col-md-6 mt-5">
                            <div className="vm-left-content">
                                <div className="main-heading main-heading-left">
                                    <h2 className="main-title wow animate__animated animate__fadeInUp">
                                        Past Presidents
                                    </h2>
                                </div>
                                <ul>
                                    <li>
                                        <p>
                                            1. Kailash Chandra
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2. S.N. Pant
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            3. Jagdish Kapur
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            4. Gen. O.P. Malhotra
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            5. Trilok Nath
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            6. Mohinder Kapur
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            7. Salil Kapur
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            8. Dipendra Manocha
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>








                        <div className="col-md-6 mt-5">
                            <div className="vm-left-content h-100" >
                                <div className="main-heading main-heading-left">
                                    <h2 className="main-title wow animate__animated animate__fadeInUp">
                                        Past General Secretaries
                                    </h2>
                                </div>
                                <ul>
                                    <li>
                                        <p>
                                            1. Vidya Batra	  1979 - 2004
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2. T. C. Alakh	  2004 - 2012
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            3. Arvind Ashdhir 2012 – 2016
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
        </>
    )
}
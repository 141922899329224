import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./TermsConditionsPage.css"
export default function TermsConditionsPage() {
    
    return (
        <>
            <section id="terms-conditions-inner-section" className="terms-conditions-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Terms & Conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Terms & Conditions
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    ???
                                </p> */}

                            </div>

                        </div>
                        <div className="col-12 terms-conditions">
                            <p>
                                Welcome to www.nabdelhi.in – the website from which you can make donations to National Association for the Blind-Delhi, a not-for-profit registered society having its office at Sector-5, R.K. Puram, New Delhi-110022, India. This organization is also known as NAB Delhi.
                            </p>
                            <p>
                                From here onwards in this agreement, the term “NAB Delhi” refers to the website www.nabdelhi.in and NAB Delhi who owns this website.
                            </p>
                            <p>
                                This Terms and Conditions of Service (“Agreement”) between NAB Delhi and users / registered members of the website (“You”) describes the terms on which NAB Delhi offers you the Services (as defined below) through the website. The use of this website is governed by the terms and conditions under this Agreement. Before using this website, you must carefully read, understand, accept and agree to abide by all the terms and conditions under this Agreement. If you do not agree to these terms and conditions under the Agreement, please do not use the website.
                            </p>
                            <p>
                                NAB Delhi reserves the right to revise, amend or modify the policy entailed in this disclaimer at any time and in any manner as it may deem fit. Any change or revision so effected will be posted on the site. It is therefore important that you review these terms regularly to ensure you are updated as to any changes.
                            </p>
                            <ol className="mt-4">
                                <li>
                                    <h5>
                                        Description of NAB Delhi services
                                    </h5>
                                    <p>The facilities and services provided by NAB Delhi (National Association for the Blind-Delhi) are as follows:
                                    </p>
                                    <ol>
                                        <li>
                                            NAB Delhi is providing education and training services to blind, low vision, Deaf-Blind and Multi-Disabled persons of all age groups. The services include pre-school preparatory, integrated school program, computer training, digital library, Braille Press, vocational training for the multi-disabled, and prevention of blindness. For more information please visit the website www.nabdelhi.in.
                                        </li>
                                        <li>
                                            Payment Gateway: If you are making donations through the payment gateway, you understand that the payment gateway is provided by a third party and governed by the terms and conditions provided by such third party. You are liable in the event you violate any of the terms and conditions of such third party in making your donations. NAB Delhi shall not be responsible for any such errors or default in making your donations.
                                        </li>
                                        <li>
                                            NAB Delhi as a facilitator: You understand that NAB Delhi is merely a facilitator for the donations to reach the intended beneficiaries. NAB Delhi shall make every effort to ensure that the donation is utilized for the purpose which is specified in the description.
                                        </li>
                                        <li>
                                            Utilization of donations and Feedback reports: NAB Delhi strives to provide a feedback report on the utilization of donations made by you within reasonable time limits. The feedback report time period may vary depending on the nature of utilization of donation.
                                        </li>
                                        <li>
                                            Tax exemption certificates: NAB Delhi will ensure that tax exemption certificates is sent to you within reasonable time limits.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                        Donor Information
                                    </h5>
                                    <ol>
                                        <li>
                                            Before making any donation, you are required to register with NAB Delhi by filling up all the mandatory information in the donation form. This information will be used for further communication in terms of tax exemption certificates, feedback reports etc. And also note that this is required as per Government of India order to record all the information about the donors.
                                        </li>
                                        <li>
                                            You acknowledge that all information on donor profile has been entered by or on behalf of you are true to the best of your knowledge. NAB Delhi shall take reasonable steps to maintain the confidentiality of such information. However, NAB Delhi does not assume any liability in case of any direct or indirect loss, injury, claim, liability or damages of any kind whatsoever arising on account of divulgence of such personal information by the visitor.
                                        </li>
                                        <li>
                                            By accepting this Agreement, You agree that you are responsible for all information provided and subsequent activities carried by you on the website until you close such account or we terminate your account in accordance with the provisions of this agreement. NAB Delhi retains the right to suspend or terminate your account without any further notice, due to reasons including but not limited to the following;
                                            <ul>
                                                <li>
                                                    Breach or violate of these Terms or other NAB Delhi agreements or guidelines,
                                                </li>
                                                <li>
                                                    requests by law enforcement or other government agencies,
                                                </li>
                                                <li>
                                                    a request by you,
                                                </li>
                                                <li>
                                                    unexpected technical issues or problems or,
                                                </li>
                                                <li>
                                                    extended periods of inactivity.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Termination of your NAB Delhi account includes removal of access to all offerings within the NAB Delhi Services and may also bar you from further use of the NAB Delhi Services. Furthermore, you agree that all terminations shall be made at NAB Delhi ’s sole discretion and that NAB Delhi shall not be liable to neither you nor any third party for any termination of your account or access to the NAB Delhi Services.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>Donations</h5>
                                    <ol>
                                        <li>
                                            You can donate to selected causes on NAB Delhi using a number of payment methods such as credit or debit cards or cheque. Donations are processed by our payment gateway service provider InstaMojo.
                                        </li>
                                        <li>
                                            All donations made through NAB Delhi are NON-REFUNDABLE. Please contact NAB Delhi immediately if there are justifying reasons or circumstances for refund and your case may be reviewed on a best effort basis and decision of NAB Delhi will be final.
                                        </li>
                                        <li>
                                            You will receive an email confirming your donation to the cause. This is the final confirmation of your donation. If you have not received please contact NAB Delhi immediately.
                                        </li>
                                        <li>
                                            NAB Delhi reserves the right at any time from time to time to modify or discontinue, temporarily or permanently the donation facility with or without notice.

                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                        Prohibited Activity
                                    </h5>
                                    <ol>
                                        <li>
                                            You agree to act in a responsible and legal manner when using the NAB Delhi Services. You shall comply with all applicable laws, regulations and rules and undertake not to use the NAB Delhi Services for any unlawful purpose or in a manner which is likely to cause harm or offense any other user including NAB Delhi.
                                        </li>
                                        <li>
                                            You agree not to post content that is patently offensive and promotes racism, bigotry, hatred or harm of any kind against any group or individual:
                                        </li>
                                        <li>
                                            You agree not to create a false identity on the website, impersonate any person or entity (including members of NAB Delhi ) or falsely state or otherwise misrepresent yourself, your name, your age or your affiliation with any person or entity;
                                        </li>
                                        <li>
                                            You agree not to submit or transmit through any material that contains viruses or any other computer codes, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network;
                                        </li>
                                        <li>
                                            NAB Delhi reserves the right to investigate and take appropriate legal action against anyone who, in NAB Delhi ’s opinion, violates this provision, including, without limitation, reporting you to appropriate law enforcement authorities.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                        Content

                                    </h5>
                                    <ol>
                                        <li>
                                            You are solely responsible for the content that you post on or through any of the NAB Delhi Services, and any material or information that you transmit to other users. NAB Delhi will not liable and does not endorse and has no control over such content.
                                        </li>
                                        <li>
                                            All trademarks reproduced in this website, which are not the property of, or licensed to NAB Delhi , are acknowledged on the website.
                                        </li>
                                        <li>
                                            Through this website links to other websites are provided for your convenience to provide further information and they are not under the control of NAB Delhi . NAB Delhi has no control over the nature, content and availability of those websites.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                        Intellectual property rights
                                    </h5>
                                    <ol>
                                        <li>
                                            Unless otherwise stated, NAB Delhi or our third parties own the intellectual property rights in the website and material on the website.
                                        </li>
                                        <li>
                                            You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                                        </li>
                                        <li>
                                            NAB Delhi grants you a personal, non-transferable and non-exclusive right and license to use the services; provided that you do not (and do not allow any third party to)
                                            <ul>
                                                <li>
                                                    Sell, rent or sub-license material from the website
                                                </li>
                                                <li>
                                                    Show any material from the website in public, without accreditation of the source;
                                                </li>
                                                <li>
                                                    Reproduce, duplicate, copy or otherwise exploit material on NAB Delhi website for a commercial purpose;
                                                </li>
                                                <li>
                                                    Edit or otherwise modify any material on the website.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                        Availability
                                    </h5>
                                    <ol>
                                        <li>
                                        NAB Delhi aspires to be a dynamic website by adding new features and content regularly for the benefit of visitors. For this purpose NAB Delhi website may be temporarily shut down for updation work. However, NAB Delhi will put its best efforts to minimize the inconvenience caused due to non-availability of website.
                                        </li>
                                        <li>
                                        At NAB Delhi we make every effort is made to keep the website up and running smoothly. However, NAB Delhi takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to issues beyond our control.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                    Indemnification
                                    </h5>
                                    <ol>
                                        <li>
                                        By accepting these Terms, you agree to indemnify and otherwise hold harmless NAB Delhi , its officers, employees, affiliates and other partners from any direct, indirect, incidental, special, consequential or exemplary damages resulting from the use of the NAB Delhi Services by you or by those who have access to the service through your account.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                    Privacy and Disclosure
                                    </h5>
                                    <ol>
                                        <li>
                                        Use of the NAB Delhi services is also governed by our Privacy Policy, which is incorporated into these terms by this reference. Without limitation of the terms and conditions set forth in our you understand and agree that NAB Delhi may disclose personally identifiable information if required to do so by law or in the good faith belief that such disclosure is necessary to comply with legal process, enforce these terms or protect the rights, property or safety of NAB Delhi , its members and the public.

                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h5>
                                    Governing Law/Dispute Resolution
                                    </h5>
                                    <ol>
                                        <li>
                                        The contract shall be governed in accordance with the laws of India and the Courts in Delhi shall have the exclusive jurisdiction to try and suit, proceedings in connection therewith/in that behalf. Any disputes under this agreement, which cannot be settled by negotiation may be resolved exclusively by arbitration and shall be held in Delhi, India and conducted in accordance with the provision of Arbitration and Conciliation Act, 1996 or any statutory modification or re-enactment thereof.\ Subject to the above, the courts of law at Delhi alone shall have the jurisdiction in respect of all matters connected with the agreement. The arbitration award shall be final and binding

                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
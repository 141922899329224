import createDOMPurify from "dompurify";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { MdPlace } from "react-icons/md";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import PostApiCall from "../../../PostApi";
import "./EventDetail.css";
const DOMPurify = createDOMPurify(window);

export default function EventDetail() {
  const params = useParams();
  const [allgalleryimages, setAllGalleryImages] = useState([]);
  const [innerImage, setInnerImage] = useState("");
  const [innerImageAlt, setInnerImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [venue, setVenue] = useState("");
  const [innerlocation, setInnerLocation] = useState("");
  const [eventid, setEventId] = useState(null);

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${params.id != null || params.id != undefined ? params.id : ""
          }`,
      },
      "GetEventById"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          PostApiCall.postRequest(
            {
              whereClause: `where fld_event_id=${params.id}`,
            },
            "GetEventsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
          setEventId(params.id);
          setTitle(obj.data[0].fld_event_name);
          setDescription(obj.data[0].fld_description);
          setInnerImage(obj.data[0].fld_inner_image);
          setInnerImageAlt(obj.data[0].fld_inner_image_alt);
          setStartDate(moment(obj.data[0].fld_start_date).format("DD-MM-YYYY"));
          setEndDate(moment(obj.data[0].fld_end_date).format("DD-MM-YYYY"));
          setVenue(obj.data[0].fld_venue);
          setInnerLocation(obj.data[0].fld_location);
        } else {
          Notiflix.Loading.remove();
          setTitle("");
          setDescription("");
          setInnerImage("");
          setInnerImageAlt("");
          setStartDate("");
          setEndDate("");
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="event-detail-section"
        className="event-detail-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Events</li>
                <li>{title}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row event-right">
            <div className="col-12 text-center">
              <div className="mb-4">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  {title}
                </h1>
                <p className="main-text mt-3 event-heading wow animate__animated animate__fadeInUp">
                  <span className="me-lg-2">
                    <BsFillCalendar2Fill />
                    <span>{`${startDate} to ${endDate}`}</span>
                  </span>
                  <span>
                    <MdPlace />
                    {venue}, {innerlocation}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-12 event-detail-card">
              <img
                src={innerImage}
                alt={innerImageAlt}
                className="img-fluid w-100 mb-4"
              />
              <div className="event-details px-2">
                <h4>{title}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              </div>
            </div>

            {allgalleryimages?.length > 0 ?
              <div className="col-md-4 my-5">
                <div className="all-service-card">
                  <div className="main-heading main-heading-left">
                    <h2 className="main-text wow animate__animated animate__fadeInUp">
                      Gallery
                    </h2>
                  </div>
                  <div className="as-card d-flex align-items-center">
                    <div className="as-img as-gallery">
                      {allgalleryimages.slice(0, 4).map((images, i) => {
                        return (
                          <img
                            src={images.fld_image}
                            alt={images.fld_image_alt}
                            className="img-fluid"
                          />
                        );
                      })}
                    </div>
                  </div>
                  <a
                    href={`/event-gallery/${eventid}`}
                    className="default-btn d-inline-block mt-4"
                  >
                    View Gallery
                  </a>
                </div>

              </div>
              : ""}
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import { useLocation } from "react-router-dom";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

export default function ProjectDetail() {
  let params = useParams();
  const [allgalleryimages, setAllGalleryImages] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectLongDescription, setProjectLongDescription] = useState("");
  const [projectid, setProjectId] = useState(null);

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${
          params.id != null || params.id != undefined ? params.id : ""
        }`,
      },
      "GetProjectById"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          PostApiCall.postRequest(
            {
              whereClause: `where fld_projects_id=${params.id}`,
            },
            "GetProjectsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
          setProjectId(params.id);
          setProjectTitle(obj.data[0].fld_project_name);
          setProjectLongDescription(obj.data[0].fld_long_description);
        } else {
          Notiflix.Loading.remove();
          setProjectTitle("");
          setProjectLongDescription("");
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="ongoing-project-inner-section"
        className="ongoing-project-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Projects</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row service-detail">
            <div className="col-lg-7">
              <div className="main-heading main-heading-left mt-4">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  {projectTitle}
                </h1>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(projectLongDescription),
                }}
              />
            </div>

            <div className="col-lg-5">
              <div className="row flex-column-reverse flex-lg-column">
                <div className="col-12 my-5">
                  <div className="all-service-card">
                    <div className="main-heading main-heading-left">
                      <h2 className="main-text wow animate__animated animate__fadeInUp">
                        Gallery
                      </h2>
                    </div>
                    <div className="as-card d-flex align-items-center">
                      <div className="as-img as-gallery">
                        {allgalleryimages.slice(0, 4).map((images, i) => {
                          return (
                            <img
                              src={images.fld_image}
                              alt={images.fld_image_alt}
                              className="img-fluid"
                            />
                          );
                        })}
                      </div>
                    </div>
                    <a
                      href={`/project-gallery/${projectid}`}
                      className="default-btn d-inline-block mt-4"
                    >
                      View Gallery
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import banner from "../../assets/images/beyond-banner.jpg";
import Navbar from "../Navbar/Navbar";
export default function BeyondPage() {
    return (

        <section
            id="completed-project-inner-section"
            className="completed-project-inner-section inner-banner mb-5">
            <Navbar />
            <div className="page-title-area">
                <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                    <div className="page-title-content">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Beyond Eyes Experience</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-12">
                        <img src={banner} className="img-fluid" alt="Try it out! Beyond Eyes Experience is a
Immersive Dark Room Experience that reveals the challenges faced by the
blind every day. Guided only by senses of touch, sound, smell & intuition
Gain a deeper understanding of empathy, perception, and the power of
human connection." />
                        <div className="main-heading pb-1 mb-4 mt-lg-4 text-center">
                            <h1 className="main-title wow animate__animated animate__fadeInUp">
                                THE BEYOND EYES EXPERIENCE
                            </h1>
                        </div>
                        <h5>NAB Delhi presents the “Beyond Eyes Experience”. Take this one-
                            of-its-kind tour in Delhi!</h5>
                        <p>Beyond Eyes Experience is a Immersive Dark Room Experience that reveals
                            the challenges faced by the blind every day. Guided only by senses of touch,
                            sound,   smell   &   intuition   Gain   a   deeper   understanding   of   empathy,
                            perception, and the power of human connection.</p>
                        <p>"Everyone is welcome to experience the challenging world beyond eyes and beyond sight. We assure you that it will be one of the best experiences of your life!"





                        </p>
                        <p>
                            The tour will take 30 to 40 minutes. It is available Monday to Saturday
                            between 10 am to 5 pm. At the time of starting the tour, you will be
                            requested   to   make   a   contribution   of   Rs.   100   per   person   towards
                            maintenance of the facilities in our campus. </p>
                        <p>
                            This Beyond Eyes Experience Tour is highly recommended to Corporates for
                            disability sensitization of their teams. To create a culture of diversity and
                            inclusion, to understand how people with disabilities work, to understand
                            how to interact with them, take this tour. It will be a great start!</p>

                        <h2>How to Book the Beyond Eyes Experience Tour</h2>
                        <p>
                            Beyond Eyes Experience is in NAB Delhi campus</p>
                        <p>National Association for the Blind </p>
                        <p>Sector -5, R.K. Puram, New Delhi 110022 India</p>
                        <p><strong>Landmark:</strong> Within walking distance from Munirka Metro Station</p>
                        <p>To book your tour Call us on <a href="tel:+91-8826261166">+91 - 88262 61166,</a> <a href="tel: +91-9212319672">+91- 92123 19672</a> or send an email to
                            <a href="mailto: helpline@nabdelhi.in"> helpline@nabdelhi.in.</a>  </p>

                    </div>
                </div>
            </div>
        </section>

    )
}
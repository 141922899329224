import React from "react";
import "./PartnersSection.css";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../../GETAPI";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// import required modules
import { Autoplay, Pagination } from "swiper";
export default function PartnersSection() {
  const [allPartnersData, setAllPartnersData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetPartnerData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllPartnersData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  const partners = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      margin: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      showDots: true,
    },
  };
  return (
    <>
      <div className="container pb-4 ps-xl-5">
        <div className="row mt-5">
          <div className="col-12 text-center">
            <div className="main-heading pb-1 mb-3">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Our Partners
              </h2>
              <p className="main-text wow animate__animated animate__fadeInUp">
                {/* Together we can Transform */}
                We are happy to collaborate with our partners
              </p>
            </div>
            <p>
              We have a good number of partners who collaborate with us and we
              are always open to have more organisation's join us in many ways
              to help the visually impaired to have a brighter future. We
              believe in building a strong partner presence who also share our
              views and ideas of helping the visually impaired.
            </p>
            {/* <a href="" alt="View our Partners" className="default-btn">View our Partners</a> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 align-items-center">
              {/* <MultiCarousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={partners}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                arrows={false}
                className="partner-slider"
              > */}
              <Swiper
                slidesPerView={2}
                slidesPerGroup={2}
                spaceBetween={30}
                loop={true}
                Keyboard={true}
                className="pt-3 pb-5 px-lg-4 px-xl-0 partner-slider"
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                  1424: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination]}
              >

                {allPartnersData.map((partners, i) => {
                  return (
                    <SwiperSlide>
                      <a href={partners.fld_company_site_link}  target="_blank" alt="Partners" aria-label="Partners">
                        <img
                          className="img-fluid"
                          src={partners.fld_image}
                          alt={partners.fld_image_alt}
                        />
                      </a>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* </MultiCarousel> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

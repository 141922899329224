import React, { useEffect, useState } from "react";
import "./ServicePage.css";
import Navbar from "../../components/Navbar/Navbar";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import GetApiCall from "../../GETAPI";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

export default function ServicePage() {
  let params = useParams();
  const [allgalleryimages, setAllGalleryImages] = useState([]);
  const [allServicesData, setAllServicesData] = useState([]);
  const [title, setTitle] = useState("");
  const [innerImage, setInnerImage] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [innerImageAlt, setInnerImageAlt] = useState("");
  const [serviceid, setServiceId] = useState(null);

  function getAllServiceData() {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetServicesData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.lengthn != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllServicesData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          setAllServicesData([]);
          Notiflix.Loading.remove();
        }
      });
    });
  }
  useEffect(() => {
    getAllServiceData();
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${
          params.id != null || params.id != undefined ? params.id : ""
        }`,
      },
      "GetServicesDataById"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          PostApiCall.postRequest(
            {
              whereClause: `where fld_services_id=${params.id}`,
            },
            "GetServicesGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
          setServiceId(params.id);
          setTitle(obj.data[0].fld_title);
          setInnerImage(obj.data[0].fld_inner_image);
          setInnerImageAlt(obj.data[0].fld_inner_image_alt);
          setLongDescription(obj.data[0].fld_long_description);
        } else {
          Notiflix.Loading.remove();
          setTitle("");
          setInnerImage("");
          setInnerImageAlt("");
          setLongDescription("");
        }
      });
    });
  }, []);

  function getCurrServiceGalleryImages(id) {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_services_id=${id}`,
      },
      "GetServicesGalleryImages"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj1.data.length != 0) {
            setAllGalleryImages(obj1.data);
            Notiflix.Loading.remove();
          } else {
            setAllGalleryImages([]);
            Notiflix.Loading.remove();
          }
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }
  return (
    <>
      <section
        id="service-inner-section"
        className="service-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
                <li>{title}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row service-detail">
            <div className="col-lg-7 h-100">
              <img
                src={innerImage}
                alt={innerImageAlt}
                className="img-fluid w-100 h-50"
              />

              <div className="main-heading main-heading-left mt-4">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  {title}
                </h1>
              </div>
              <div className="service-inner-content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(longDescription),
                }}
              />
            </div>

            <div className="col-lg-5">
              <div className="row flex-column-reverse flex-lg-column">
                <div className="col-12">
                  <div className="all-service-card">
                    <div className="main-heading main-heading-left">
                      <h2 className="main-text wow animate__animated animate__fadeInUp">
                        Other Services
                      </h2>
                    </div>
                    {allServicesData.map((services, i) => {
                      return (
                        <div className="as-card d-flex align-items-center mb-4 service-inner-card">
                          <div className="as-img">
                            <img
                              src={services.fld_cover_image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setInnerImage(services.fld_inner_image);
                              setInnerImageAlt(services.fld_inner_image_alt);
                              setLongDescription(services.fld_long_description);
                              setTitle(services.fld_title);
                              getCurrServiceGalleryImages(services.fld_id);
                            }}
                          >
                            <h3 className="main-text wow animate__animated animate__fadeInUp">
                              {services.fld_title}
                            </h3>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12 my-5">
                  <div className="all-service-card">
                    <div className="main-heading main-heading-left">
                      <h2 className="main-text wow animate__animated animate__fadeInUp">
                        Gallery
                      </h2>
                    </div>
                    <div className="as-card d-flex align-items-center">
                      <div className="as-img as-gallery">
                        {allgalleryimages.slice(0, 4).map((images, i) => {
                          return (
                            <img
                              src={images.fld_image}
                              alt={images.fld_image_alt}
                              className="img-fluid"
                            />
                          );
                        })}
                      </div>
                    </div>
                    <a
                      href={`/services-gallery/${serviceid}`}
                      className="default-btn d-inline-block mt-4"
                    >
                      View Gallery
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

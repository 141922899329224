import React from "react";
import "./AwardPage.css";
import Navbar from "../../components/Navbar/Navbar";
export default function SanjayTandon() {

    return (
        <>
            <section id="award-inner-section" className="award-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Awards & Scholarships</li>
                                <li>Sanjay Tandon Memorial Scholarship</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="main-heading text-center pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Sanjay Tandon Memorial Scholarship
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi has been nationally and internationally recognized for its pioneering work
                                    in the interest of the visually impaired people. Among many awards and
                                    felicitations, the two key awards which NAB has received are following
                                </p> */}
                            </div>
                            <p>
                                The Sanjay Tandon Memorial Scholarship was instituted with the support of Mr. K.C. Tandon in
                                the memory of his son Mr. Sanjay Tandon who passed away at a young age. The objective of this
                                scholarship is to provide financial assistance for higher education to meritorious visually
                                impaired students from economically weaker sections of the society.
                            </p>
                            <p>
                                Since its inception in 2007, this scholarship scheme has positively impacted the lives of many
                                visually impaired students by providing them with much needed financial assistance to complete
                                their education.
                            </p>
                            <p>
                                Applications are invited for the Sanjay Tandon Memorial Scholarship by NAB Delhi every year
                                in the months of July-August. A committee has been constituted by NAB Delhi with
                                representation from the Tandon family to select deserving candidates from the applicants.
                            </p>
                            <p>
                                Please contact us for more information on this scholarship scheme or to start similar schemes for
                                visually impaired students.
                            </p>
                        </div>
                       
                    </div>

                </div>
            </section>
        </>
    );
}
import React, { Component } from "react";
import BackToTop from "react-back-to-top-button";
import { MdKeyboardArrowUp } from "react-icons/md";
import "./top.css"
function TopBtn() {
    return (
        <BackToTop
            showOnScrollDown
            showAt={50}
            speed={3000}
            easing="easeInOutQuint"
            aria-label=" Go Top Button"
        >
            <button className="myBtn" aria-label=" Go Top Button">
                    <MdKeyboardArrowUp className="arrow"/>
            </button>
        </BackToTop>
    );
}
export default TopBtn;
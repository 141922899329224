import Notiflix from "notiflix";
// var urlString = "http://localhost:8039/nabdapi/";
var urlString = "https://cms.nabdelhi.in/nabdapi/";
// var schema;
// if (localStorage.getItem("ClientDetails") != null) {
//   schema = JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_schema;
// }
const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        // "x-auth-token": JSON.parse(localStorage.getItem("access")),
        schema: "nabdelhi_db",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          Notiflix.Notify.failure(
            "You are not authorized to access this page. Please login and try again."
          );
          window.location.href = "/";
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error;
      });
  },
};
export default GetApiCall;

import createDOMPurify from "dompurify";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import "swiper/css";
import donorimage from "../../../assets/images/beyond-logo.jpg";
import GetApiCall from "../../../GETAPI";
import "./DonarSection.css";


export default function DonarSection() {
    const [donorsList, setDonorsList] = useState([])
    const DOMPurify = createDOMPurify(window);
    useEffect(() => {
        Notiflix.Loading.dots();
        GetApiCall.getRequest("GetDOW").then((resultdes) => {
            resultdes.json().then((obj) => {
                if (obj.data.length !== 0) {
                    var activeobjdata = obj.data.filter(objdata =>
                        objdata.fld_status === "Active" &&
                        objdata.fld_showOnHomePage === "Yes"
                    );
                    setDonorsList(activeobjdata);
                    Notiflix.Loading.remove();
                } else {
                    Notiflix.Loading.remove();
                    setDonorsList([]);
                }
            });
        });
    }, []);
    return (
        <div className="row">
            {donorsList.length > 0 &&
                <div className="col-lg-6 mb-md-5 mb-4">
                    <div className="three-section-card three-section-card-1">
                        <div className="ts-icon">
                            <h3>
                                Donor of the Day
                            </h3>
                        </div>
                        {donorsList?.map((data) => {
                            return (
                                <div className="ts-content mb-0">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(data.fld_shortcontent),
                                        }}
                                    />
                                    {/* <p>
                                    Extraordinary donors who have
                                    made significatn impact by
                                    contirbuting Rs. 5000 and
                                    above
                                </p>
                                <div className="text-center donor-date-section">
                                    <p>29 April 2024</p>
                                    <p>Sumit verma</p>
                                    <p>Ajay Kumar</p>
                                    <p>Ramesh Sinha</p>
                                </div> */}
                                </div>
                            )
                        })}

                        <div className="mt-3">
                            <a href="/donors" alt="View All Donors" className="default-btn" aria-label="View All Donors">View All</a>
                        </div>
                    </div>
                </div>
            }
            <div className="col-lg-6 mb-md-5 mb-4">
                <div className="three-section-card three-section-card-2">
                    <div className="ts-icon">
                        <h3>
                            Beyond Eyes Experience
                        </h3>
                    </div>
                    <div className="ts-content mb-0">
                        <div className="text-center">
                            <img src={donorimage} className="img-fluid w-60 mx-auto mb-4" alt="Beyond Eyes Logo" />
                        </div>
                        <p>
                            Immersive Dark Room
                            Experience that
                            reveals the challenges faced
                            by the blind every-day
                        </p>
                        <div className="mt-4">
                            <a href="/beyond-eyes-experience" alt="more   information   about   the   beyond
eyes experience" className="default-btn" aria-label="more   information   about   the   beyond
eyes experience">More Information </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

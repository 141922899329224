import React from "react";
import "./AboutOrganisation.css";
import Navbar from "../../Navbar/Navbar";
import imgone from "../../../assets/images/about/about-main.png";
import imgtwo from "../../../assets/images/about/about-small.png";
import ImgLeft from "../../../assets/images/banner/event.png";

import NationalAward from "../../../assets/images/awards/national-award.png";
import Assistech from "../../../assets/images/awards/Assistech-Foundation.jpg";
import NationalAwardTwo from "../../../assets/images/awards/international-excellence-award.png";
import DivyangSamaan from "../../../assets/images/awards/DivyangSamaan.jpg";
import image from "../../../assets/images/awards/DSC_9079.jpg";

export default function AboutOrganisation() {
  return (
    <>
      <section
        id="about-inner-section"
        className="about-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              {/* <h2>About Us</h2>
                            <h3>???</h3> */}
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 about-organisation mb-5">
          <div className="row">
            <div className="col-12 text-center order-1">
              <div className="main-heading pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  About Us
                </h1>
                <h2 className="main-text wow animate__animated animate__fadeInUp">
                  National Association for the Blind – Delhi
                </h2>
                <p>Also known as NAB Delhi and NAB</p>
              </div>
            </div>
            <div className="col-lg-7 order-3 order-lg-2">
              <div className="main-heading main-heading-left">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  What we do?
                </h2>
                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    A non-profit organisation working for the visually impaired
                                </p> */}
              </div>
              <p>
                The National Association for the Blind, Delhi, is a prominent
                organization based in Delhi, India, that has been working for
                over 45 years towards the empowerment of individuals who are
                with blindness, low vision, deafblind and multi-disabled,
                through quality inclusive education, and inclusion in other
                aspects of life.
              </p>
              {/* <p>
                Nab Delhi aims to improve the quality of life for visually
                impaired individuals by providing a wide range of services and
                programs. These initiatives are designed to address various
                aspects of their lives, including education, employment,
                rehabilitation, and social integration. The organization's
                primary objective is to ensure equal opportunities and rights
                for the visually impaired community.
              </p> */}
              <p>
                NAB Delhi aims to improve the quality of life of its target
                group of persons with disabilities by providing a wide range of
                services and programs. These initiatives are designed to address
                various aspects of their lives, including education, employment,
                rehabilitation, and social integration. The organization
                endeavours to secure for them their rights and equal
                opportunities.
              </p>
              {/* <p>
                                National Association for the Blind Delhi has also been a pioneer in initiating services for
                                children with visual impairment and additional disabilities since 1997. Centre for Multiple
                                disabilities provides individualized intervention to multisensory impaired/deaf-blind children.
                                It is also recognized as a Regional Learning Centre on Deaf-blindness (North) providing
                                training on deaf-blindness to various NGOs working in the disability sector.
                            </p> */}
            </div>
            <div className="col-lg-5 order-2 order-lg-3">
              <div className="about-inner-img">
                <img className="img-fluid" src={imgone} alt=""></img>
                <img className="img-fluid" src={imgtwo} alt=""></img>
                {/* <div className="about-inner-year">
                                    Since 1979
                                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-left">
                <h3 className="main-title wow animate__animated animate__fadeInUp">
                  Education
                </h3>
              </div>
              <p>
                Education is a key focus area for NAB Delhi. The organization
                runs educational programs that cater to the unique needs of
                students who are with blindness, low vision, deafblind and
                multi- disabled. A team of Special Educators provides
                specialized teaching methodologies, assistive technologies, and
                resources to support students' academic and personal
                development. NAB Delhi promotes inclusive education and
                advocates for mainstreaming of its students into mainstream
                schools.
              </p>
            </div>
            <div className="col-12">
              <div className="main-heading main-heading-left">
                <h3 className="main-title wow animate__animated animate__fadeInUp">
                  Training
                </h3>
              </div>
              <p>
                Additionally, NAB Delhi offers vocational training programs to
                enhance the employability of individuals who are with blindness,
                low vision, deafblind and multi-disabled. These programs equip
                them with skills in various fields such as computer literacy,
                assistive technology, digital accesibility, alternate format
                creation, Braille transcription, audio production, and more.
                These skills, enable them to lead independent and financially
                secured lives.
              </p>
            </div>
            <div className="col-12">
              <div className="main-heading main-heading-left">
                <h3 className="main-title wow animate__animated animate__fadeInUp">
                  Rehabilitation
                </h3>
              </div>
              <p>
                Provision of rehabilitation products and services such as
                assistive devices, including mobility aids, Braille equipment,
                talking books, and access to information and technology and
                counselling and psychological support to address the emotional
                and social challenges is another important aspect of Nab Delhi's
                work.
              </p>
            </div>
            <div className="col-12">
              <div className="main-heading main-heading-left">
                <h3 className="main-title wow animate__animated animate__fadeInUp">
                  Inclusion
                </h3>
              </div>
              <p>
                In addition to education and rehabilitation, NAB Delhi actively
                advocates for the rights and inclusion of persons with
                disabilities in the society. The organization works closely with
                government bodies, policymakers, and other organizations to
                influence policy changes and ensure that the needs of our target
                group of persons with disabilities are considered in various
                spheres of life.
              </p>
              <p>
                NAB Delhi also organizes awareness campaigns, sensitization
                programs, and events to promote public understanding and
                acceptance of visually impaired individuals. These initiatives
                aim to break down barriers and create an inclusive society where
                visually impaired individuals can fully participate and
                contribute.
              </p>
              <p>
                Overall, NAB Delhi plays a vital role in improving the lives of
                visually impaired individuals in Delhi. Through its
                comprehensive range of services, the organization strives to
                empower the visually impaired community, promote their
                independence, and create a more inclusive society.
              </p>
            </div>
            <div className="col-12 my-3 about-page">
              <div className="main-heading text-center pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Who We Are?
                </h2>
              </div>
              <ul>
                <li>
                  <p>
                    <b>
                      Non-governmental organisation (NGO) registered as a
                      Charitable Society in 1979.
                    </b>
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Working for the blind, low vision, deafblind and
                      multi-disabled of all age groups.
                    </b>
                  </p>
                </li>
                <li>
                  <p>Focussed on empowerment of persons with disabilities.</p>
                </li>
                <li>
                  <p>
                    Received Best Institution National Award from The President
                    of India in 2008.
                  </p>
                </li>
                <li>
                  <p>Certified by Credibility Alliance for good governance.</p>
                </li>
                <li>
                  <p>Certified by Guide Star for transparency.</p>
                </li>
                <li>
                  <p>
                    Having certificate and flawless track record of implementing
                    CSR projects with private and public sector companies.
                  </p>
                </li>
                <li>
                  <p>
                    Having 80G approval, donors are eligible for income tax
                    exemptions.
                  </p>
                </li>
                <li>
                  <p>
                    Having valid FCRA certificate, eligible to receive funds
                    from outside India.
                  </p>
                </li>
                <li>
                  <p>
                    Presence in USA, donations made by USA citizens are exempt
                    from Federal income tax under section 501(c) (3) of the
                    Internal Revenue Code of USA through Angels for Angels
                    (Fiscal Sponsor).
                  </p>
                </li>
                <li>
                  <p>Registered with Benevity and Give India.</p>
                </li>
                <li>
                  <p>
                    Has own building in R.K. Puram, Dwarka and Narela in Delhi .
                  </p>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0 text-center text-lg-start">
              <div className="">
                <img className="img-fluid" src={ImgLeft} alt=""></img>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="vm-right">
                <h2 className="main-heading main-heading-left wow animate__animated animate__fadeInUp">
                  Vision
                </h2>

                {/* <p>
                  To see the persons who are blidness, low vision, deafblind and multi-disabled in India as empowered
                  and well-informed individuals working towards personal growth
                  and development thereby leading life to its optimum potential.
                </p> */}
                {/* <p>To see the persons who are blidness, low vision, deafblind and multi-disabled in India as empowered and well-informed individuals working towards personal growth and development thereby leading life to its optimum potential to persons who are with.... */}

                <p>
                  To see the persons who are with blindness, low vision,
                  deafblind and multi-disabled in India as empowered and
                  well-informed individuals working towards personal growth and
                  development thereby leading life to its optimum potential.
                </p>

                <h2 className="main-heading main-heading-left wow animate__animated animate__fadeInUp">
                  Mission
                </h2>
                <p>
                  To empower persons who are with blindness, low vision, are
                  deafblind and multi-disabled with education and training to
                  attain self-sufficiency through employment self- employment
                  thus, integrating them with dignity in mainstream society.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 text-center mb-4">
              <div className="main-heading pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Awards &amp; Recognition
                </h2>

                <p className="main-text wow animate__animated animate__fadeInUp">
                  NAB Delhi has been nationally and internationally recognized
                  for its pioneering for individuals who are with blindness, low
                  vision, are deafblind and multi-disabled. Among many awards
                  and felicitations, the two key awards which NAB has received
                  are:
                </p>
              </div>
            </div>
          </div>
          <div className="row award-section mb-5">
            <div className="col-lg-6 text-center">
              <img
                alt="NAB Delhi’s General Secretary Shri. Prashant Ranjan Verma Recieivng the Award."
                src={Assistech}
                className="border"></img>
            </div>
            <div className="col-lg-6">
              <p>
                We are thrilled to share that the National Association for the
                Blind, Delhi has been honored with the prestigious “Best
                Assistive Technology NGO” award at the ATF Awards 2024, held
                during the Bengaluru Tech Summit!
              </p>
              <p>
                This recognition celebrates our relentless efforts in leveraging
                Assistive Technology to empower individuals with disabilities.
                It reaffirms our commitment to fostering inclusion,
                accessibility, and innovation for a better future.
              </p>
              <p>
                We extend our heartfelt gratitude to the Assistech Foundation
                for this honor and to all our supporters and partners who make
                our journey impactful. Together, let’s continue to shape a more
                inclusive world!
              </p>
            </div>
          </div>
          <div className="row award-section mb-5 ">
            <div className="col-lg-6 order-lg-1 order-2">
              {/* <div className="main-heading main-heading-left">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  National Award
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Our Award-Winning Achievements
                </p>
              </div> */}
              <p>
                15th NCPEDP-Mphasis Universal Design Award: A Distinguished
                Recognition for NAB Delhi
              </p>
              <p>
                {" "}
                We are incredibly proud to have received Jury Appreciation at
                the 15th NCPEDP-Mphasis Universal Design Awards 2024. This
                prestigious recognition, conferred by NCPEDP and Mphasis on
                August 14th, 2024 at the Lalit Hotel, New Delhi, celebrates our
                significant contributions towards creating inclusive and
                accessible environments.
              </p>
              <p>
                This esteemed award, under the Role Model Companies &
                Organizations category, is a testament to our commitment to
                universal design and our efforts in setting new benchmarks in
                accessibility. It highlights our ongoing journey towards a
                society that embraces inclusivity and equal opportunities for
                all, especially persons with disabilities.
              </p>
            </div>
            <div className="col-lg-6 text-center order-lg-2 order-1">
              <img alt="National Award" src={image} className="img-fluid"></img>
            </div>
          </div>
          <div className="row award-section">
            <div className="col-lg-6 text-center">
              <img
                alt="National Award"
                src={NationalAward}
                className="img-fluid"></img>
            </div>
            <div className="col-lg-6">
              {/* <div className="main-heading main-heading-left">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  National Award
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Our Award-Winning Achievements
                </p>
              </div> */}
              <p>
                National Award for the Best Organization conferred by Hon'ble
                Vice President of India on the occasion of International Day of
                the Persons with Disability on 3rd December 2008.
              </p>
            </div>
          </div>
          <div className="row award-section flex-lg-row-reverse mt-5">
            <div className="col-lg-6 text-center">
              <img
                alt="Divyangjan Swabhimaan Sammaan Award"
                src={DivyangSamaan}
                className="img-fluid"></img>
            </div>
            <div className="col-lg-6">
              {/* <div className="main-heading main-heading-left">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Divyangjan Swabhimaan Sammaan Award
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A Proud Moment
                </p>
              </div> */}
              <p>
                Divyangjan Swabhimaan Sammaan Award by the esteemed “Servants of
                the People Society Delhi,” a legacy founded by the revered late
                Shri Lala Lajpat Rai and Anam Sneh Parivar Prayagraj conferred
                by honorable Minister of Education and Minister of Skill
                Development and Entrepreneurship in the Government of India on
                October 2nd, 2023.
              </p>
              {/* <p>
                This distinguished accolade is bestowed by the esteemed
                “Servants of the People Society Delhi,” a legacy founded by the
                revered late Shri Lala Lajpat Rai and Anam Sneh Parivar
                Prayagraj. The award was presented by none other than the
                honorable Minister of Education and Minister of Skill
                Development and Entrepreneurship in the Government of India,
                Shri Dharmendra Pradhan.
              </p> */}
            </div>
          </div>
          <div className="row award-section mt-5">
            <div className="col-lg-6 text-center">
              <img
                alt="International Excellence Award"
                src={NationalAwardTwo}
                className="img-fluid"></img>
            </div>
            <div className="col-lg-6">
              {/* <div className="main-heading main-heading-left">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  International Excellence Award
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Excellence on the World Stage
                </p>
              </div> */}
              <p>
                <b>The International Excellence Award </b>
                from the Hon'ble Minister of Social Justice & Empowerment,
                Government of India on 23rd August 2017.
              </p>
              <p>
                NAB Delhi also organizes awareness campaigns, sensitization
                programs, and events to promote public understanding and
                acceptance of visually impaired individuals. These initiatives
                aim to break down barriers and create an inclusive society where
                visually impaired individuals can fully participate in and
                contribute to.
              </p>
              <p>
                This comprehensive approach demonstrates NAB Delhi&#39;s
                commitment to improving the lives of visually impaired
                individuals in Delhi and beyond. Through our extensive range of
                services and programs, the organization strives to empower the
                visually impaired community, promote their independence, and
                create a more inclusive society.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="story-section approach-section py-5 my-5">
        <div className="container pb-5 pt-3 ">
          <div className="row pb-0 pt-5 py-lg-5">
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Our Approach
                </h2>
                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                  The way we set us apart
                </p> */}
              </div>
              <p className="text-white mb-5">
                What sets us apart from all our fellow organizations is our
                contemporary approach and focus on social integration of the
                blindness/blind, low vision, deafblind and multi-disabled into
                the society. We provide a diverse and individualized learning
                environment to our students. We also provide them with the
                technical skills to keep up with modern world's requirements
                from a very early age.
              </p>
            </div>

            <div className="col-lg-4 approach-card-1">
              <div className="approach-card">
                <h2>Empowerment</h2>
                <p>
                  We empower our students by providing them with the essential
                  life skills like mobility and orientation, self care, and
                  technical knowledge to navigate the world around them
                  independently.
                </p>
              </div>
            </div>
            <div className="col-lg-4 approach-card-2-main">
              <div className="approach-card approach-card-2">
                <h2>Education</h2>
                <p>
                  Once the students have developed the necessary learning
                  capabilities, we provide them world class education through
                  our partner schools who ensure that our student's learning is
                  at par with their sighted counterparts.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="approach-card approach-card-3">
                <h2>Inclusion</h2>
                <p>
                  We ensure all our students, once completed their school
                  education are able to pursue higher education or skill
                  development for effective integration into the society. We
                  provide training and assistance in their career building
                  journey as well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

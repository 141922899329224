import React from "react";
import "./AwardPage.css";
import Navbar from "../Navbar/Navbar";
import BrochureAward from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/Brochure-award-2023.pdf";
import MedhaBrocher from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/Medha-Brochure-2024.pdf"
export default function SudhaVerma() {
  return (
    <>
      <section
        id="award-inner-section"
        className="award-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Awards & Scholarships</li>
                <li>NAB Sudha Verma Young Achiever Award</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  NAB Sudha Verma Young Achiever Award
                </h1>
                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi has been nationally and internationally recognized for its pioneering work
                                    in the interest of the visually impaired people. Among many awards and
                                    felicitations, the two key awards which NAB has received are following
                                </p> */}
              </div>
              <p>
                This award was instituted by Rashmi Kiran Mallick, Prashant
                Ranjan Verma and Sumit Ranjan Verma in the loving memory of
                their mother late Smt. Sudha Verma. This award recognizes
                non-academic outstanding achievement of a visually impaired in
                non-academic fields such as music, sports, arts and adventure.
              </p>
            </div>

            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Previous Awardees
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2024</h3>

                    <ul className="mt-3 ps-4">
                      <li><a href={MedhaBrocher} download>MS. SIMRAN CHAWLA</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2023</h3>

                    <ul className="mt-3 ps-4">
                      <li><a href={BrochureAward} download>Annpurna</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2022</h3>

                    <ul className="mt-3 ps-4">
                      <li>CHHONZIN ANGMO</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2021</h3>
                    <ul className="mt-3 ps-4">
                      <li>ANKUR DHAMA</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2020</h3>

                    <ul className="mt-3 ps-4">
                      <li>DANISH MAHAJAN</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2019</h3>

                    <ul className="mt-3 ps-4">
                      <li>ANKUR GUPTA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="mt-3">
                The awardees are chosen by a committee setup by the NAB Delhi
                management. Applications are not invited for this award.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

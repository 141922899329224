import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import icon1 from "../../../assets/images/icons/1.png";
import icon2 from "../../../assets/images/icons/2.jpg";
import icon3 from "../../../assets/images/icons/3.png";
import DonarSection from '../DonarSection/DonarSection';
import './AboutSection.css';
function AboutSection() {
  return (
    <>
      <section className="about-style-1 pt-lg-5 pt-5">
        <Container>
          <Row className='mb-5'>
            <Col lg={12} className='about-num-section text-center mb-3'>
              {/* <img src='https://nabdelhi.in/images/bg_4.jpg' className='img-fluid'></img> */}
              <div className='main-heading mb-3 pb-1'>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  We are Making a Difference !
                </p>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Alone we can do so little,
                  together we can do so much.
                </p>

                <p>~ Hellen Keller</p>
              </div>
              <p>
                NAB Delhi’s prime objective has been to make visually impaired persons both self-reliant and contributing members of the society.
              </p>
            </Col>

            <Col lg={12} className='about-num-section-bottom'>

              <ul className='row justify-content-lg-between align-items-lg-center main-section h-100'>
                <div className='col-lg-4'>
                  <li>
                    <img src={icon1} className='img-fluid w-25 mb-2' alt=''></img>
                    <span> 1,00,000 +</span>
                    <p> Beneficiaries</p>
                  </li>
                </div>
                <div className='col-lg-4'>
                  <li>
                    <img src={icon3} className='img-fluid' alt='NAB Delhi received Best Institution National Award from Government of India'></img>
                    {/* <span>5,000 +</span>
                    <p>Books Converted to ePUB</p> */}
                  </li>
                </div>
                <div className='col-lg-4'>
                  <li>
                    <img src={icon2} className='img-fluid' alt='NAB Delhi has been certified for Good Governance by the Credibility Alliance'></img>
                    {/* <span>
                      7,000 +</span>
                    <p>Made Digitally Literate</p> */}
                  </li>
                </div>
              </ul>

            </Col>
          </Row>
          <DonarSection />
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <h1 className="main-title wow animate__animated animate__fadeInUp">
                Serving Individuals with Special Needs


              </h1>
              <p className="main-text wow animate__animated animate__fadeInUp">
                National Association for the Blind Delhi is a pioneer in initiating services for children with visual impairment and additional disabilities.
              </p>
              <p className="main-sub-text mt-4 wow animate__animated animate__fadeInUp">
                ‘Tamaso Ma Jyotirgamaya’ (Lead us from
                Darkness to Light) is the guiding principle which
                has become an omnipresent philosophy on
                which all of NAB’s facilities and strategies are
                formulated. Our Motto is <b>‘GROWTH WITH
                  DIGNITY’</b> – A Vision for Visually Impaired
              </p>

              {/* <p className='b-h1'>
                  We are Making a Difference !
                </p>
                <p className='b-h2'>
                  Alone we can do so little,
                  together we can do so much.
                </p>
                <p>~ Hellen Keller</p> */}
              {/* <p className='mt-4'>
                  NAB Delhi’s prime objective has been to make visually impaired persons both self-reliant and contributing members of the society.
                </p>
                <ul className='d-lg-flex justify-content-lg-between mt-4 '>
                  <li>
                    <span> 80,000</span>
                    <span> Beneficiaries</span>
                  </li>
                  <li>
                    <span>5,000 </span>
                    <span>Books Converted to ePUB</span>
                  </li>
                  <li>
                    <span>
                      7,000 </span>
                    <span>Made Digitally Literate</span>

                  </li>
                </ul>  */}
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
}

export default AboutSection;
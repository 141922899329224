import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import "./AcademyPage.css";
import createDOMPurify from "dompurify";
import { useLocation, useParams } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../PostApi";

export default function InnerAcademyPage() {
  const DOMPurify = createDOMPurify(window);
  const [data, setData] = useState([]);
  const params = useParams();

  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${params.id}`,
      },
      "GetInnerAcademyDataById"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj1.data.length != 0) {
            setData(obj1.data);
            Notiflix.Loading.remove();
          } else {
            setData([]);
            Notiflix.Loading.remove();
          }
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  return (
    <>
      <section
        id="terms-conditions-inner-section"
        className="terms-conditions-inner-section inner-banner mb-4"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Academy | {data[0]?.fld_title}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 academy-image">
              <img
                className="img-fluid"
                src={data[0]?.fld_coverimage}
                alt={data[0]?.fld_coverimagealt}
              ></img>{" "}
            </div>
            <div className="col-lg-8 mt-lg-0 mt-3">
              <>
                <h2 class="wow animate__animated animate__fadeInUp main-heading main-heading-left mb-4">
                  {data[0]?.fld_title}
                </h2>
                <p>{data[0]?.fld_shortdescription}</p>
              </>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-3 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data[0]?.fld_longdescription),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useState } from "react";
import "./Footer.css";
import moment from "moment";
import { ImLocation } from "react-icons/im";
import { IoMdCall, IoMdMail } from "react-icons/io";
import packageInfo from "../../../package.json";
import AdmissionModal from "../AdmissionModal/AdmissionModal";
import qr from "../../assets/images/qr.jpeg"
function Footer() {
  return (
    <>
      <footer className="footer-section white-bg">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 ps-xl-5">
              <div className="single-footer-widget">
                <h2>About Us</h2>

                <ul className="footer-quick-links">
                  <li>
                    <a href="/about-organisation">About The Organisation</a>
                  </li>
                  <li>
                    <a href="/history-achievements">Vision & Mission</a>
                  </li>
                  <li>
                    <a href="/our-team">Our Team</a>
                  </li>
                  <li>
                    <a href="/governing-council">Governing Council</a>
                  </li>
                  <li>
                    <a href="/downloads">Downloads</a>
                  </li>
                  <li>
                    <a
                      href="/photo-gallery"
                      alt="Photo Gallery "
                      aria-label="Photo Gallery ">
                      Photo Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      href="/video-gallery"
                      alt="Video Gallery "
                      aria-label="Video Gallery ">
                      Video Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>NAB Home</h3>

                <ul className="footer-quick-links">

                  <li>
                    <a href="/">
                      ??

                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      Services We Provide

                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      How You Can Help ?
                    </a>
                  </li>

                </ul>
              </div>
            </div> */}

            <div className="col-lg-3 col-md-6 col-sm-6 ps-lg-4">
              <div className="single-footer-widget ps-lg-5">
                <h2>Quick Links</h2>

                <ul className="footer-quick-links">
                  <li>
                    <a
                      href="/news-blogs"
                      alt="News & Blogs "
                      aria-label="News & Blogs ">
                      News & Blogs
                    </a>
                  </li>
                  {/* <li>
                    <a alt="Admissions " aria-label="Admissions ">
                      <AdmissionModal />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/newsletter"
                      alt="Newsletter "
                      aria-label="Newsletter ">
                      Newsletter
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/events"
                      alt="Newsletter "
                      aria-label="Newsletter "
                    >
                      Events
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/terms-conditions"
                      alt="Terms & Conditions"
                      aria-label="Terms & Conditions ">
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/refund-policy"
                      alt="Terms & Conditions "
                      aria-label="Refund Policy ">
                      Refund Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/disclaimer"
                      alt="Disclaimer "
                      aria-label="Disclaimer ">
                      Disclaimer
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <div className="single-footer-widget mb-0">
                <h2 className="mb-2">Support us! Scan to Donate Now!</h2>

              </div>
              <img src={qr} className="img-fluid w-60 mx-auto " alt="Support us! Scan to Donate Now!" />



            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ps-lg-3">
              <div className="single-footer-widget ps-lg-5">
                <h2 className="mt-5 mt-lg-0">Contact Us</h2>

                {/* <div className="footer-news">
                  <a href="/event">
                    <img src="https://templates.envytheme.com/huruma/default/assets/img/footer-news/1.jpg" alt="image" />
                    <h4>Support about poverty to poor family</h4>
                    <span>Poo`r, 22 January</span>
                  </a>
                </div>

                <div className="footer-news">
                  <a href="/event">
                    <img src="https://templates.envytheme.com/huruma/default/assets/img/footer-news/2.jpg" alt="image" />
                    <h4>Mostly suffered school Boys care</h4>
                    <span>Health, 24 February</span>
                  </a>
                </div> */}
                <div className="footer-info-contact">
                  <div className="d-flex">
                    <ImLocation />
                    <p>Location</p>
                  </div>
                  <span>
                    <a
                      href="https://goo.gl/maps/TVUndTrVQC53h91W6"
                      className="d-flex"
                      target={"_blank"}>
                      National Association for the Blind, <br /> Sector 5, Rama
                      Krishna Puram, <br />
                      Delhi - 110022, INDIA.
                    </a>
                  </span>
                </div>

                <div className="footer-info-contact">
                  <div className="d-flex">
                    <IoMdCall />
                    <p>Call Us</p>
                  </div>
                  <span>
                    <a href="tel:+918826260010">+91 - 88262 60010</a>
                  </span>{" "}
                  <br />
                  <span>
                    <a href="tel:+918826261166">+91 - 88262 61166</a>
                  </span>
                </div>

                <div className="footer-info-contact">
                  <div className="d-flex">
                    <IoMdMail />
                    <p>Email Us</p>
                  </div>
                  <span>
                    <a href="mailto:info@nabdelhi.in">info@nabdelhi.in</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area px-xl-5">
          <div className="container border-top pt-4">
            <div className="copyright-area-content">
              <div className="row align-items-center flex-column-reverse flex-lg-row">
                {/* <div className="col-lg-2 text-center text-lg-start">
                  <div className="copyright-logo">
                    <a href='/'> <img src={Logo} className="w-50 mb-lg-2 mb-3" alt="image" /></a>
                  </div>
                </div> */}

                <div className="col-lg-4 mt-2 mt-lg-0 text-center text-lg-start">
                  <ul>
                    <li>
                      Design{" "}
                      <a href="http://globaltrendz.com/" target={"_blank"}>
                        Global Trendz
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8 text-center text-lg-end">
                  <p className="m-0">
                    Copyright &copy; {moment().format("YYYY")}{" "}
                    <b className="d-block d-lg-inline">
                      {" "}
                      NATIONAL ASSOCIATION FOR THE BLIND, DELHI.{" "}
                    </b>
                    All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="text-center pt-3">
                <span className="text-white">
                  v-{packageInfo.version}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

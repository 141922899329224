import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./StoriesPage.css";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";

export default function StoriesPage() {
  const [allStoriesData, setAllStoriesData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetStoriesData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllStoriesData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="story-inner-section"
        className="story-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Impact Stories</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <div className="main-heading pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Impact Stories
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A look at our talented students
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {allStoriesData.map((stories, i) => {
              return (
                <div className="col-md-4 px-3 mb-3">
                  <div className="story-card">
                    <div className="storie-img">
                      <img
                        className="img-fluid"
                        src={stories.fld_image}
                        alt={stories.fld_image_alt}
                      />
                    </div>
                    <div className="story-content">
                      <h4>{stories.fld_name}</h4>
                      <h5>
                        {stories.fld_designation}, {stories.fld_company_name}
                      </h5>
                      <p className="stories-description">
                        {stories.fld_description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import "./AwardPage.css";
import Navbar from "../../components/Navbar/Navbar";
import NationalAward from "../../assets/images/awards/national-award.png";
import NationalAwardTwo from "../../assets/images/awards/international-excellence-award.png";
export default function AwardPage() {
    
    return (
        <>
            <section id="award-inner-section" className="award-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Awards & Scholarships</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Awards & Achievements
                                </h1>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi has been nationally and internationally recognized for its pioneering work
                                    in the interest of the visually impaired people. Among many awards and
                                    felicitations, the two key awards which NAB has received are following
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row award-section">
                        <div className="col-lg-6 text-center">
                            <img alt="NAB Delhi" src={NationalAward} className="img-fluid"></img>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-heading main-heading-left">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    National Award
                                </h2>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                Our Award-Winning Achievements
                                </p>
                            </div>
                            <p>
                                NAB Delhi is a recipient of the National
                                Award for the Best Organization
                                conferred by Hon'ble Vice President of
                                India on the occasion of International Day
                                of the Persons with Disability on 3rd
                                December 2008
                            </p>
                        </div>
                    </div>
                    <div className="row award-section flex-lg-row-reverse mt-5">
                        <div className="col-lg-6 text-center">
                            <img alt="NAB Delhi" src={NationalAwardTwo} className="img-fluid"></img>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-heading main-heading-left">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    International Excellence
                                    Award
                                </h2>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                Excellence on the World Stage
                                </p>
                            </div>
                            <p>
                                NAB Delhi has also received the
                                International Excellence Award from the
                                Hon'ble Minister of Social Justice &
                                Empowerment, Government of India on
                                23rd August 2017.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
import React from "react";
import "./StorySection.css";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../../GETAPI";
import { useState } from "react";
import { useEffect } from "react";
import Notiflix from "notiflix";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
export default function StorieSection() {
  const [allStoriesData, setAllStoriesData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetStoriesData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllStoriesData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section className="story-section py-5">
        <div className="container pb-2 py-lg-5">
          <div className="row pt-5 pb-4 py-lg-5">
            <div className="col-12 text-center">
              <div className="main-heading pb-1 mb-3">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Impact Stories
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A look at our talented students
                </p>
              </div>
              <p className="text-white">
                Benefitting from the quality education and opportunities
                provided by NAB Delhi, blind students have reached amazing
                heights in their careers in both the private and government
                sector. Here are just a few of our heros.
              </p>
              {/* <a href="/impact-stories" alt="Read More Stories" className="default-btn story-section-btn mx-auto">Read More Stories</a> */}
            </div>
            <div className="col-12 mt-5">
              <div className="row">
                {/* <MultiCarousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={3000}
                                    arrows={false}

                                > */}
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  Keyboard={true}
                  className="pt-3 pb-5 px-lg-4 px-xl-0"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                    1424: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination]}
                >
                  {allStoriesData.map((stories, i) => {
                    return (
                      <SwiperSlide>
                        <div className="story-card">
                          <div className="storie-img">
                            <img
                              className="img-fluid"
                              src={stories.fld_image}
                              alt={stories.fld_image_alt}
                            />
                          </div>
                          <div className="story-content">
                            <h3>{stories.fld_name}</h3>
                            <p className="s-des">
                              {stories.fld_designation},{" "}
                              {stories.fld_company_name}
                            </p>
                            <p className="stories-description">
                              {stories.fld_description}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {/* </MultiCarousel> */}
              </div>
            </div>
          </div>

          {/* <div className="row d-none d-lg-flex">
                        <div className="col-md-3 story-bottom-box">
                            <div className="main-heading main-heading-left main-heading-2">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Our Partners
                                </h2>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                    Together we can Transform
                                </p>
                            </div>
                            <p className="text-white">
                                We have a good number of partners who collaborate with us and we are always open to have more organisation's join us in many ways to help the visually impaired to have a brighter future. We believe in building a strong partner presence who also share our views and ideas of helping the visually impaired.
                            </p>
                            <a href="/partners" alt="View our Partners" className="default-btn">View our Partners</a>
                        </div>

                    </div> */}
        </div>
      </section>
    </>
  );
}

import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./RefundPolicyPage.css"
export default function RefundPolicyPage() {
    
    return (
        <>
            <section id="refund-policy-inner-section" className="refund-policy-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Refund Policy</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                Refund Policy
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    ???
                                </p> */}

                            </div>

                        </div>
                        <div className="col-12 refund-policy">
                            <p>
                            Welcome to www.nabdelhi.in – the website from which you can make donations to National Association for the Blind-Delhi, a not-for-profit registered society having its office at Sector-5, R.K. Puram, New Delhi-110022, India. This organization is also known as NAB Delhi.

                            </p>
                            <p>
                            From here onwards in this agreement, the term “NAB Delhi” refers to the website www.nabdelhi.in and NAB Delhi who owns this website.

                            </p>
                            <p>
                            NAB Delhi reserves the right to revise, amend or modify the policy entailed in this disclaimer at any time and in any manner as it may deem fit. Any change or revision so effected will be posted on the site. It is therefore important that you review these terms regularly to ensure you are updated as to any changes.
                            </p>
                           
                                    <h5>
                                    Refund Policy on Donations given to NAB Delhi
                                    </h5>
                           <ol>
                            <li>
                            You can donate to selected causes on NAB Delhi using a number of payment methods such as credit or debit cards or cheque. Donations are processed by our payment gateway service provider InstaMojo.

                            </li>
                            <li>
                            All donations made through NAB Delhi are NON-REFUNDABLE. Please contact NAB Delhi immediately if there are justifying reasons or circumstances for refund and your case may be reviewed on a best effort basis and decision of NAB Delhi will be final.

                            </li>
                            <li>
                            You will receive an email confirming your donation to the cause. This is the final confirmation of your donation. If you have not received please contact NAB Delhi immediately.

                            </li>
                            <li>
                            NAB Delhi reserves the right at any time from time to time to modify or discontinue, temporarily or permanently the donation facility with or without notice.

                            </li>
                           </ol>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
import { createContext, useContext, useState, use } from "react";
const store = createContext()
export const Provider = ({ children }) => {
    const [activeItem, setActiveItem] = useState(' ');
    return (
        <store.Provider value={{
            setActiveItem,activeItem
        }}>
            {children}
        </store.Provider>
    )
}
export const Store = () => {
    const storeData = useContext(store);
    return storeData
}
export default Store
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import GetApiCall from "../../../GETAPI";
import Navbar from "../../Navbar/Navbar";
import "./OfficeBearers.css";

export default function OfficeBearers() {
  const [allOfficeBearersData, setAllOfficeBearersData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetMemberData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length !== 0) {
          var activeobjdata = obj.data.filter(objdata =>
            objdata.fld_status === "Active" &&
            objdata.fld_member_type === "Office Bearers");
          setAllOfficeBearersData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setAllOfficeBearersData([]);
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="office-inner-section"
        className="office-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
                <li>Our Team</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 pb-3 about-organisation">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Our Team
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Meet Our Team of Inspiring Individuals: Passionate about Creating a Brighter Tomorrow






                </p>
              </div>
            </div>
            {allOfficeBearersData.map((officebearers, i) => {
              return (
                <div className="col-6 col-md-3 mb-5">
                  <div className="office-card">
                    <img
                      src={officebearers.fld_image}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="office-content">
                      <h2>{officebearers.fld_name}</h2>
                      <p>{officebearers.fld_designation}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

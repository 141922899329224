import React, { useState, useEffect } from "react";
import "./GoverningCouncil.css";
import Navbar from "../../Navbar/Navbar";
import Notiflix from "notiflix";
import GetApiCall from "../../../GETAPI";

export default function GoverningCouncil() {
  const [allGoverningCouncilData, setAllGoverningCouncilData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetMemberData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (
              objdata.fld_status === "Active" &&
              objdata.fld_member_type === "Governing Council"
            ) {
              activeobjdata.push(objdata);
            }
          });
          setAllGoverningCouncilData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setAllGoverningCouncilData([]);
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="office-inner-section"
        className="office-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
                <li>Governing Council</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 pb-3 about-organisation">
          <div className="row justify-content-lg-around">
            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Governing Council
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Leading the Way to a Better Tomorrow: Meet Our Visionary
                  Governing Council Members
                </p>
              </div>
            </div>
            {/* <div className="col-6 col-md-2 me-lg-3 mb-4">
                            <div className="governing-card">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" className="img-fluid" /> 
                                <div className="governing-content">
                                    <h5>
                                    T. D. Dhariyal	
                                    </h5>
                                    <h6>President</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 me-lg-3 mb-4">
                            <div className="governing-card">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" className="img-fluid" /> 
                                <div className="governing-content">
                                    <h5>
                                    Subhash Chandra Vashishth	
                                    </h5>
                                    <h6>Joint Secretary</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 me-lg-3 mb-4">
                            <div className="governing-card">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" className="img-fluid" /> 
                                <div className="governing-content">
                                    <h5>
                                    T. D. Dhariyal	
                                    </h5>
                                    <h6>President</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 me-lg-3 mb-4">
                            <div className="governing-card">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" className="img-fluid" /> 
                                <div className="governing-content">
                                    <h5>
                                    T. D. Dhariyal	
                                    </h5>
                                    <h6>President</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 me-lg-3 mb-4">
                            <div className="governing-card">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" className="img-fluid" /> 
                                <div className="governing-content">
                                    <h5>
                                    T. D. Dhariyal	
                                    </h5>
                                    <h6>President</h6>
                                </div>
                            </div>
                        </div> */}
            <div className="col-12">
              <table class="content-table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {allGoverningCouncilData.map((governingcouncil, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{governingcouncil.fld_name}</td>
                        <td>{governingcouncil.fld_designation}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

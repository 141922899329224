import React from "react";
import "./Partners.css";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { useState } from "react";
import GetApiCall from "../../GETAPI";

export default function Partners() {
  const [allPartnersData, setAllPartnersData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetPartnerData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllPartnersData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="partners-inner-section"
        className="partners-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
                <li>Partners</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 pb-3 about-organisation">
          <div className="row">
            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Partners
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  ???
                </p>
              </div>
            </div>
            {allPartnersData.map((partners, i) => {
              return (
                <div className="col-6 col-md-3 mb-4">
                  <div className="partner-card">
                    <a href={partners.fld_company_site_link} target="_blank" alt="Partners" aria-label="Partners">
                    <img
                      className="img-fluid"
                      src={partners.fld_image}
                      alt={partners.fld_image_alt}
                    />
                    </a>
                    <div className="partner-content">
                      <h5>{partners.fld_company_name}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import "./EventSection.css";
import EventBg from "../../../assets/images/banner/event.png";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Notiflix from "notiflix";
import GetApiCall from "../../../GETAPI";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
export default function EventSection() {
  const [allEventsData, setAllEventsData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetEventsData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          activeobjdata.reverse();
          setAllEventsData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      margin: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      showDots: true,
    },
  };

  return (
    <>
      <section className="event-section mt-5">
        <img src={EventBg} alt="NAB Delhi"></img>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center event-left">
              <div className="main-heading pb-1 mb-3">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Events
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A sneak peak of what's happening at NAB
                </p>
              </div>
              <p>
                NAB organises a lot of Events, Seminars, Conferences & Programs
                individually or in association with various organisations. You
                can get to see the upcoming events and the events previously
                held. Events where Visitors are invited can viewed here for you
                to reserve your seats.
              </p>
              <p className="event-left-p">
                Keep coming back to visit our website to know more about our
                events.
              </p>
              {/* <a href="/events" alt="View All Events" aria-label='View All Events' className="default-btn event-left-a">View All Events</a> */}
            </div>
            <div className="col-12">
              <div className="event-right row align-items-center">
                {/* <MultiCarousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  arrows={false}
                > */}
                <Swiper
                  slidesPerView={1}
                  slidesPerGroup={1}
                  spaceBetween={30}
                  loop={true}
                  Keyboard={true}
                  className="pt-3 pb-5 px-lg-5 px-xl-0"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 50,
                      slidesPerGroup: 2,
                    },
                    1224: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                      slidesPerGroup: 2,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination]}>
                  {allEventsData.slice(0, 6).map((events, i) => {
                    return (
                      <SwiperSlide>
                        <div className="event-card ">
                          <img
                            alt={events.fld_cover_image_alt}
                            className="img-fluid"
                            src={events.fld_cover_image}
                          />
                          <div className="event-right-content">
                            <span>
                              {moment(events.fld_start_date).format(
                                "DD-MM-YYYY"
                              )}{" "}
                              to{" "}
                              {moment(events.fld_end_date).format("DD-MM-YYYY")}
                            </span>
                            <a
                              href={`/event-detail/${events.fld_event_name.replace(
                                / /g,
                                "-"
                              )}/${events.fld_id}`}
                              // aria-label="Event "
                            >
                              {events.fld_event_name}
                            </a>
                            <p className="event-location mb-0">{events.fld_venue}</p>
                            <p className="event-location">
                              {events.fld_location}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {/* </MultiCarousel> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

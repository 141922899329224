import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import "./NewsBlogSection.css";

import moment from "moment";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../../GETAPI";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// import required modules
import { Autoplay, Pagination } from "swiper";
export default function NewsBlogSection() {
  const [allNewsBlogData, setAllNewsBlogsData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetNewsBlogData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length !== 0) {
          var activeobjdata = obj.data.filter(objdata => objdata.fld_status === "Active");
          activeobjdata.reverse();
          setAllNewsBlogsData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);


  return (
    <>
      <section id="blog-section" className="blog-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  News & Blogs
                </h2>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A few news, stories and articles from our team of writers &
                  researchers
                </p>
              </div>
            </div>
            {/* <MultiCarousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
            > */}
            <Swiper
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={30}
              loop={false}
              Keyboard={true}
              className="pt-3 pb-5 px-lg-5 px-xl-0"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                  slidesPerGroup: 2,
                },
                1224: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                  slidesPerGroup: 2,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination]}
            >
              {allNewsBlogData.slice(0, 4).map((newsblogs, i) => {
                return (
                  <SwiperSlide>
                    <div className="blog-card">
                      <div className="blog-img">
                        <span>
                          {newsblogs.fld_updated_on == null
                            ? moment(newsblogs.fld_created_on).format(
                              "DD MM YYYY"
                            )
                            : moment(newsblogs.fld_updated_on).format(
                              "DD MM YYYY"
                            )}
                        </span>
                        <a
                          href={`/news-blog-detail/${newsblogs.fld_title.replace(
                            / /g,
                            "-"
                          )}/${newsblogs.fld_id}`}
                        // alt={`${newsblogs.fld_title},`}
                        // aria-label={`${newsblogs.fld_title},`}
                        >
                          <img
                            alt={newsblogs.fld_image_alt}
                            src={newsblogs.fld_image}
                            className="img-fluid"
                          />
                        </a>
                        <div className="blog-content">
                          <a
                            href={`/news-blog-detail/${newsblogs.fld_title.replace(
                              / /g,
                              "-"
                            )}/${newsblogs.fld_id}`}
                            // alt={`${newsblogs.fld_title},`}
                            // aria-label={`${newsblogs.fld_title},`}
                            className="home-blog-heading"

                          >
                            {newsblogs.fld_title}
                          </a>
                          <p>{newsblogs.fld_short_description}</p>
                          <a
                            href={`/news-blog-detail/${newsblogs.fld_title.replace(
                              / /g,
                              "-"
                            )}/${newsblogs.fld_id}`}
                            alt={`${newsblogs.fld_title},`}
                            aria-label={`${newsblogs.fld_title},`}
                          >
                            <BiRightArrowAlt />
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {/* </MultiCarousel> */}
          </div>
        </div>
      </section>
    </>
  );
}

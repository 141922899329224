import createDOMPurify from "dompurify";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import GetApiCall from "../../GETAPI";
import Navbar from "../Navbar/Navbar";
import "./donars.css";
export default function DonarSection() {
  const [donorsList, setDonorsList] = useState([]);
  const DOMPurify = createDOMPurify(window);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetDOW").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length !== 0) {
          var activeobjdata = obj.data.filter(
            (objdata) => objdata.fld_status === "Active"
          );
          setDonorsList(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          setDonorsList([]);
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="completed-project-inner-section"
        className="completed-project-inner-section inner-banner mb-5">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Donars</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 text-center">
              <div className="main-heading pb-1 mb-4">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Donors
                </h1>
              </div>
            </div>
            {donorsList?.map((data) => {
              return (
                <>
                  <div className="col-xl-4 col-xxl-3 col-md-6 mb-4">
                    <div className="three-section-card three-section-card-1 doror-card">
                      <div className="ts-icon">
                        <h3>Donor of the Day</h3>
                      </div>
                      <div className="donor-date-section">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data.fld_shortcontent),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

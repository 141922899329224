import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContactPage from "../components/ContactPage/ContactPage";


function Contactpage() {
    
    return (
      <>
        <section id="contact-inner-section" className="contact-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ContactPage />
        </section>
      </>
    );
}

export default Contactpage;
import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../../components/Navbar/Navbar";
import "./NewsBlogPage.css";
import { BiRightArrowAlt } from "react-icons/bi";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";

export default function NewsBlogPage() {
  const [allNewsBlogData, setAllNewsBlogsData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetNewsBlogData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if ((objdata.fld_status = "Active")) {
              activeobjdata.push(objdata);
            }
          });
          setAllNewsBlogsData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="news-blogs-inner-section"
        className="news-blogs-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>News & Blog</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  News & Blogs
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A few news, stories and articles from our team of writers &
                  researchers
                </p>
              </div>
            </div>
            {allNewsBlogData.map((newsblogs, i) => {
              return (
                <div className="col-md-6 p-3">
                  <div className="blog-card">
                    <div className="blog-img">
                      <span>
                        {newsblogs.fld_updated_on == null
                          ? moment(newsblogs.fld_created_on).format(
                              "DD MM YYYY"
                            )
                          : moment(newsblogs.fld_updated_on).format(
                              "DD MM YYYY"
                            )}
                      </span>
                      <img
                        alt={newsblogs.fld_image_alt}
                        src={newsblogs.fld_image}
                        className="img-fluid"
                      />
                      <div className="blog-content">
                        <h4>
                          <a
                            href={`/news-blog-detail/${newsblogs.fld_title.replace(
                              / /g,
                              "-"
                            )}/${newsblogs.fld_id}`}
                            alt="News and Blog "
                            aria-label="News and Blog "
                          >
                            {newsblogs.fld_title}
                          </a>
                        </h4>
                        <p>{newsblogs.fld_short_description}</p>
                        <a
                          href={`/news-blog-detail/${newsblogs.fld_title.replace(
                            / /g,
                            "-"
                          )}/${newsblogs.fld_id}`}
                          alt="News and Blog "
                          aria-label="News and Blog "
                        >
                          <BiRightArrowAlt />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./AlumniPage.css"
export default function AlumniPage() {
    
    return (
        <>
            <section id="terms-conditions-inner-section" className="terms-conditions-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Alumni</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                Coming Soon
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    ???
                                </p> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
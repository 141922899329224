import React, { useState, useEffect } from "react";
import "./Downloads.css";
import Navbar from "../../Navbar/Navbar";
import { BsFileEarmarkPdf } from "react-icons/bs";
import Notiflix from "notiflix";
import GetApiCall from "../../../GETAPI";

export default function Downloads() {
  const [importantpublication, SetImportantPublication] = useState([]);
  const [rechlistcedu, SetRechListcEdu] = useState([]);
  const [annualreports, SetAnnualReports] = useState([]);
  const [auditedaccounts, SetAuditedAccounts] = useState([]);
  const [legaldocuments, SetLegalDocuments] = useState([]);
  const [beneficiarydata, SetBeneficiaryData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetDownloadsData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeimportantpubobjdata = [];
          var activerecholisticobjdata = [];
          var activeannualreportsobjdata = [];
          var activeauditedaccobjdata = [];
          var activelegaldocumentsobjdata = [];
          var activebeneficaryobjdata = [];

          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              if (objdata.fld_category === "IMPORTANT PUBLICATIONS") {
                activeimportantpubobjdata.push(objdata);
              } else if (
                objdata.fld_category ===
                "REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS"
              ) {
                activerecholisticobjdata.push(objdata);
              } else if (objdata.fld_category === "AUDITED ACCOUNTS") {
                activeauditedaccobjdata.push(objdata);
              } else if (objdata.fld_category === "LEGAL DOCUMENTS") {
                activelegaldocumentsobjdata.push(objdata);
              } else if (objdata.fld_category === "BENEFICIARY DATA") {
                activebeneficaryobjdata.push(objdata);
              } else if (objdata.fld_category === "ANNUAL REPORTS") {
                activeannualreportsobjdata.push(objdata);
              }
            }
          });
          SetImportantPublication(activeimportantpubobjdata);
          SetRechListcEdu(activerecholisticobjdata);
          SetAnnualReports(activeannualreportsobjdata);
          SetAuditedAccounts(activeauditedaccobjdata);
          SetLegalDocuments(activelegaldocumentsobjdata);
          SetBeneficiaryData(activebeneficaryobjdata);
          Notiflix.Loading.remove();
        } else {
          SetImportantPublication("");
          SetRechListcEdu("");
          SetAnnualReports("");
          SetAuditedAccounts("");
          SetLegalDocuments("");
          SetBeneficiaryData("");
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="downloads-inner-section"
        className="downloads-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
                <li>Downloads</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 pb-3 about-organisation">
          <div className="row">
            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Downloads
                </h1>
                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                  Explore Our Library: Download Annual Reports, Legal Documents,
                  Beneficiary Data, and Publications to Learn More
                </p> */}
              </div>
            </div>

            <div
              className="col-md-6 mb-5"
              style={{
                display: importantpublication.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>Important Documents</h2>
                {importantpublication.length != 0 ? (
                  importantpublication?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="col-md-6 mb-5"
              style={{
                display: rechlistcedu.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>
                  REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH
                  Blindness
                </h2>
                {rechlistcedu.length != 0 ? (
                  rechlistcedu?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="col-md-6 mb-5"
              style={{
                display: auditedaccounts.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>Audited Accounts</h2>
                {auditedaccounts.length != 0 ? (
                  auditedaccounts?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="col-md-6 mb-5"
              style={{
                display: legaldocuments.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>Legal Documents</h2>
                {legaldocuments.length != 0 ? (
                  legaldocuments?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="col-md-6 mb-5"
              style={{
                display: beneficiarydata.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>Beneficiary Data</h2>
                {beneficiarydata.length != 0 ? (
                  beneficiarydata?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="col-md-6 mb-5"
              style={{
                display: annualreports.length != 0 ? "block" : "none",
              }}
            >
              <div className="download-card">
                <h2>Annual Reports</h2>
                {annualreports.length != 0 ? (
                  annualreports?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((documentdata, i) => {
                    return (
                      <a href={documentdata.fld_document} target="_blank">
                        <span>{documentdata.fld_name}</span>
                        <BsFileEarmarkPdf />
                      </a>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

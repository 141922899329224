import React from "react";
import "./ContactPage.css";
import { useState, useEffect } from "react";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
export default function ContactPage() {
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [mobileno, SetMobileno] = useState("");
  const [message, SetMessage] = useState("");
  const [Enquiry, setEnquiry] = useState({
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  });
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      useFontAwesome: false,
      timeout: 4000,
    });
  }, []);

  function onSaveContactInfo() {
    if (name != "") {
      if (email != "") {
        if (Enquiry.EmailRegex.test(email)) {
          if (mobileno != "") {
            if (mobileno.length == 10) {
              Notiflix.Loading.arrows("Please wait...");
              PostApiCall.postRequest(
                {
                  id: null,
                  name: name,
                  email: email,
                  mobilenumber: mobileno,
                  message: message,
                },
                "AddContact"
              ).then((result) =>
                result.json().then((obj) => {
                  if (result.status == 200 || result.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success(
                      "Contact Information Submitted Successfully"
                    );
                    SetName("");
                    SetEmail("");
                    SetMobileno("");
                    SetMessage("");
                  } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Error Occured!");
                  }
                })
              );
            } else {
              Notiflix.Notify.failure("Please enter valid mobile number");
            }
          } else {
            Notiflix.Notify.failure("Please enter mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter valid email.");
        }
      } else {
        Notiflix.Notify.failure("Please enter email address.");
      }
    } else {
      Notiflix.Notify.failure("Please enter name");
    }
  }
  return (
    <>
      <div className="container pt-5 contact-section">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left pb-1">
              <h1 className="main-title wow animate__animated animate__fadeInUp">
                Have a Question? Drop a Line
              </h1>
              <p className="main-text wow animate__animated animate__fadeInUp">
                We value your feedback and appreciate your interest in our
                organization.
              </p>
            </div>
            <form>
              <div className="form-field">
                <label for="name">
                  Name <span>*</span>
                </label>
                <input
                id="name"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    SetName(e.target.value);
                  }}
                />
              </div>
              <div className="form-field">
                <label for="email">
                  Email <span>*</span>
                </label>
                <input
                id="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    SetEmail(e.target.value);
                  }}
                />
              </div>
              <div className="form-field">
                <label for="mobile-number">
                  Mobile Number <span>*</span>
                </label>
                <input
                id="mobile-number"
                  type="text"
                  maxLength="10"
                  value={mobileno}
                  onChange={(e) => {
                    SetMobileno(e.target.value);
                  }}
                />
              </div>
              <div className="form-field">
                <label for="message">Message</label>
                <textarea
                id="message"
                  cols={1}
                  rows={4}
                  value={message}
                  onChange={(e) => {
                    SetMessage(e.target.value);
                  }}
                />
              </div>
              <div className="w-100 text-end">
                <button
                  type="button"
                  className="default-btn me-0"
                  onClick={onSaveContactInfo}>
                  Send
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 my-5 my-lg-0">
            <div className="contact-info">
              <div className="main-heading main-heading-left">
                <h2 className="main-title text-white wow animate__animated animate__fadeInUp">
                  Contact Details
                </h2>
              </div>
              <div className="d-flex flex-wrap w-100 justify-content-between">
                <div>
                  <h3>Address</h3>
                  <a
                    href="https://goo.gl/maps/TVUndTrVQC53h91W6"
                    target={"_blank"}>
                    National Association for the Blind, <br /> Sector 5, Rama
                    Krishna Puram, <br />
                    Delhi - 110022, INDIA.
                  </a>
                </div>
                <div>
                  <h3>Email</h3>
                  <a href="mailto:info@nabdelhi.in">
                    info@nabdelhi.in
                  </a>
                </div>
                <div className="d-flex flex-column w-50 mt-4">
                  <h3>Contact Numbers</h3>
                  <a href="tel:+918826261166">
                    +91 88262 61166
                  </a>
                  <a href="tel:+919212319672">
                    +91 92123 19672
                  </a>
                  <a href="tel:+918826260010">
                    +91 88262 60010
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import "./CardSection.css";
import { GiBlackHandShield } from "react-icons/gi";
import { BiDonateHeart } from "react-icons/bi";
import csricon from "../../../assets/images/CSR.png";
import volunteericon from "../../../assets/images/volunteer.png";
import donationicon from "../../../assets/images/donation.png";
export default function CardSection() {
    return (
        <>
            <section className="card-section py-5 ">
                <div className="container py-3 px-xl-5 px-xxl-0">
                    <div className="row">
                        <div className="col-lg-12 text-center t-card-img mb-3">
                            {/* <img src="https://nabdelhi.in/images/p1.jpg" className="img-fluid w-100 " alt="NAB Delhi"></img> */}
                            <div className="main-heading pb-1 mb-3">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    {/* Empowering the Visually Impaired */}
                                    How Can You Help?
                                </h2>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                    {/* Want to know how you can Help? */}
                                    Your Support Matters: Let's Transform the Lives of the Visually Impaired, Together
                                </p>
                            </div>
                            {/* <p>
                                Make a Difference in the Lives of the Visually Impaired.
                            </p> */}
                            <p>
                                You can be part of the cause in multiple ways. Each and every small effort taken by you as a Partner, Volunteer or as a individual donor can bring about a huge difference.
                            </p>
                            {/* <p className="">According to the World Health Organization, around <span> 40 million</span> people in the world are blind, while another <span> 250 million</span> have some form of visual impairment.</p> */}
                        </div>
                        {/* <div className="col-12 main-card-section py-lg-5">
                            <div className="row"> */}
                        <div className="col-lg-6 col-xl-4 mb-md-5 mb-4">
                            <div className="three-section-card three-section-card-1">
                                <div className="ts-icon">
                                    <img src={volunteericon} className="img-fluid" alt="Be a Volunteer" />
                                    <h3>
                                        Be a Volunteer
                                    </h3>
                                </div>
                                <div className="ts-content">

                                    <p>
                                        Looking to make a difference in the lives of visually impaired individuals in Delhi? Consider volunteering with NAB Delhi. We welcome volunteers of all backgrounds and provide training and support to help you make a positive impact in the community. Whether you want to help with programs, events, fundraising, or other initiatives, we have opportunities for everyone.

                                    </p>
                                </div>
                                <div>
                                    <a href="/be-a-volunteer" alt="Be a Volunteer" className="default-btn" aria-label="Be a Volunteer">Join Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 mb-md-5 mb-4">
                            <div className="three-section-card three-section-card-2">
                                <div className="ts-icon">
                                    <img src={donationicon} className="img-fluid" alt="Donate Now" />
                                    <h3>
                                        Give Donation
                                    </h3>
                                </div>
                                <div className="ts-content">

                                    <p>
                                        Your donation can make a significant difference in the lives of these individuals by providing them with access to education, healthcare, and job opportunities. With your support, NAB Delhi can continue to provide essential services and programs to those in need. Every contribution, no matter how small, can help create a brighter future for the visually impaired community in Delhi.
                                    </p>
                                </div>
                                <div>
                                    <a href="/donate" alt="Donate Now" className="default-btn" aria-label=" Donate Now ">Donate Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 mb-md-5 mb-4">
                            <div className="three-section-card three-section-card-3">
                                <div className="ts-icon">
                                    <img src={csricon} className="img-fluid" alt="Be a CSR Partner"></img>
                                    <h3>
                                        Join as CSR
                                    </h3>
                                </div>
                                <div className="ts-content">

                                    <p>
                                        Joining hands with NAB Delhi can make a difference in the lives of visually impaired individuals. As a CSR partner, your organization can help provide vital resources and services to those in need. By supporting us, your organization can contribute to the empowerment and inclusion of the visually impaired community. Join us today to create a positive impact and fulfill your CSR objectives.
                                    </p>
                                </div>
                                <div>
                                    <a href="/be-a-csr" alt="Be a CSR Partner" aria-label=" Be a CSR Partner    " className="default-btn">Be a CSR Partner</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 p-0">
                                    <div className="three-section-card three-section-card-4">
                                        <BsFillChatQuoteFill />
                                        <h3>
                                        According to the World Health Organization, around <span> 40 million</span> people in the world are blind, while another <span> 250 million</span> have some form of visual impairment.
                                        </h3>
                                    </div>
                                </div> */}
                    </div>
                </div>
                {/* </div>
                </div> */}
            </section>
        </>
    );
}
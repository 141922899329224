import React from "react";
import About from "../components/HomePage/AboutSection/AboutSection";
import EventSection from "../components/HomePage/EventSection/EventSection";
import HomeBanner from "../components/HomePage/HomeBanner/HomeBanner";
import StorySection from "../components/HomePage/ImpactStoriesSection/StorySection";
import NewsBlogSection from "../components/HomePage/NewsBlogSection/NewsBlogSection";
import NewsLetterSection from "../components/HomePage/NewsLetterSection/NewsLetterSection";
import PartnersSection from "../components/HomePage/PartnersSection/PartnersSection";
import ServiceSection from "../components/HomePage/ServiceSection/ServiceSection";
import CardSection from "../components/HomePage/ThreeCardsSection/CardSection";


function Homepage() {

  return (
    <>
      {/* <Navbar /> */}
      <HomeBanner />

      <About />


      <ServiceSection />

      <CardSection />

      <StorySection />
      <PartnersSection />
      {/* <ProjectSection /> */}
      <EventSection />
      <NewsBlogSection />
      <NewsLetterSection />
      {/* <Services />
        <Testimonials />
        <PhotoGallery /> */}
      {/* <VideoGallery /> */}
    </>
  );
}

export default Homepage;
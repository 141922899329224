import React from "react";
import "./VolunteerPage.css";
import Navbar from "../../components/Navbar/Navbar";
import { useState , useEffect} from "react";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";

export default function VolunteerPage() {
    const [name, SetName] = useState("");
    const [age, SetAge] = useState("");
    const [gender, SetGender] = useState("");
    const [email, SetEmail] = useState("");
    const [contactnumber, SetContactNumber] = useState("");
    const [city, SetCity] = useState("");
    const [organization, SetOrganization] = useState("");
    const [areaofinterest, SetAreaOfIntereset] = useState("");
    const [howlong, SetHowlong] = useState("");
    const [eduqualification, SetEduQualification] = useState("");
    const [address, SetAddress] = useState("");
    const [anyotherInfo, SetAnyOtherInfo] = useState("");
    const [Enquiry, setEnquiry] = useState({
        EmailRegex:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    })

    useEffect(() => {
        Notiflix.Notify.init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            fontSize: "15px",
            useFontAwesome: false,
            timeout: 4000,
        });
      }, []);

    function onsaveVolunteerdata() {
        if (name != "") {
            if (email != "") {
                if (
                    Enquiry.EmailRegex.test(email)
                ) {
                    if (contactnumber != "") {
                        if (contactnumber.length == 10) {
                            Notiflix.Loading.arrows("Please wait...");
                            PostApiCall.postRequest({
                                id: null,
                                name: name,
                                gender: gender,
                                age: age,
                                email: email,
                                contactnumber: contactnumber,
                                city: city,
                                organization: organization,
                                areaofinterest: areaofinterest,
                                howlong: howlong,
                                eduqualification: eduqualification,
                                address: address,
                                anyotherinfo: anyotherInfo
                            },
                                "AddVolunteer").then((result) => result.json().then((obj) => {
                                    if (result.status == 200 || result.status == 201) {

                                        PostApiCall.postRequest({
                                            id: null,
                                            name: name,
                                            email: email
                                        },
                                            "AddVolunteerClientMailer")
                                            .then((result) => result.json().then((obj) => {
                                                if (result.status == 200 || result.status == 201) {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.success("Volunteer Information Submitted Successfully");
                                                    SetName("")
                                                    SetEmail("")
                                                    SetAge("")
                                                    SetGender("")
                                                    SetContactNumber("")
                                                    SetCity("")
                                                    SetOrganization("")
                                                    SetAreaOfIntereset("")
                                                    SetHowlong("")
                                                    SetEduQualification("")
                                                    SetAddress("")
                                                    SetAnyOtherInfo("")
                                                }
                                            }))
                                    }
                                    else {
                                        Notiflix.Loading.remove();
                                        Notiflix.Notify.failure("Error Occured!");
                                    }
                                }))
                        }
                        else {
                            Notiflix.Notify.failure("Please enter valid mobile number");

                        }
                    }
                    else {
                        Notiflix.Notify.failure("Please enter mobile number");
                    }
                }
                else {
                    Notiflix.Notify.failure(
                        "Please enter valid email."
                    );
                }
            } else {
                Notiflix.Notify.failure(
                    "Please enter email address."
                );
            }
        }
        else {
            Notiflix.Notify.failure("Please enter name");
        }
    }
    return (
        <>
            <section id="volunteer-inner-section" className="volunteer-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Volunteer Form</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row service-detail">
                        <div className="col-12 text-center">
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Be a Volunteer
                                </h1>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                    Join NAB Delhi's Mission to Empower the Visually Impaired
                                </p>
                            </div>

                        </div>
                        <div className="col-12 mt-lg-2 ps-ld-4 volunteer-section">
                            <form className="row">
                                <div className="form-field col-md-4">
                                    <label for="name">
                                        Name <span>*</span>
                                    </label>
                                    <input id="name" type="text" value={name} onChange={(e) => {
                                        SetName(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="age">
                                        Age <span>*</span>
                                    </label>
                                    <input id="age" type="email" value={age} onChange={(e) => {
                                        SetAge(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="gender">
                                        Gender <span>*</span>
                                    </label>
                                    <select id="gender" onChange={(e) => { SetGender(e.target.value) }}>
                                        <option hidden>-select-</option>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                        <option value='Other'>Other</option>
                                    </select>
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="email">
                                        Email <span>*</span>
                                    </label>
                                    <input id="email" type="text" value={email} onChange={(e) => {
                                        SetEmail(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="contact-number">
                                        Contact Number <span>*</span>
                                    </label>
                                    <input id="contact-number" type="text" maxLength="10" value={contactnumber} onChange={(e) => {
                                        SetContactNumber(e.target.value);
                                    }} />
                                </div>


                                <div className="form-field col-md-4">
                                    <label for="city">
                                        City <span>*</span>
                                    </label>
                                    <input id="city" type="text" value={city} onChange={(e) => {
                                        SetCity(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="organization">
                                        Organization
                                    </label>
                                    <input id="organization" type="text" value={organization} onChange={(e) => {
                                        SetOrganization(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="area-of-interest">
                                        Area Of Interest
                                    </label>
                                    <input id="area-of-interest" type="text" value={areaofinterest} onChange={(e) => {
                                        SetAreaOfIntereset(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="how-long-would-you-like-to-volunteer?">
                                    How long would you like to volunteer?
                                    </label>
                                    <input id="how-long-would-you-like-to-volunteer?" type="text" value={howlong} onChange={(e) => {
                                        SetHowlong(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="educational-qualification">
                                        Educational Qualification <span>*</span>
                                    </label>
                                    <textarea id="educational-qualification" cols={1} rows={4} value={eduqualification} onChange={(e) => {
                                        SetEduQualification(e.target.value);
                                    }} />
                                </div>
                                <div className="form-field col-md-4">
                                    <label for="address">
                                        Address(Optional)
                                    </label>
                                    <textarea id="address" cols={1} rows={4} value={address} onChange={(e) => {
                                        SetAddress(e.target.value);
                                    }} />
                                </div>

                                <div className="form-field col-md-4">
                                    <label for="please-share-any-additional-information">
                                        Please share any additional information
                                    </label>
                                    <textarea id="please-share-any-additional-information" cols={1} rows={4} value={anyotherInfo} onChange={(e) => {
                                        SetAnyOtherInfo(e.target.value);
                                    }} />
                                </div>
                                <div className="col-12 my-2 text-end">
                                    <button type="button" className="default-btn me-0" onClick={onsaveVolunteerdata}>Submit</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./EventPage.css";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import moment from "moment";

export default function EventPage() {
  const [allEventsData, setAllEventsData] = useState([]);
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetEventsData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllEventsData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="event-inner-section"
        className="event-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Events</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row event-right">
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Events
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  A sneak peak of what's happening at NAB
                </p>
              </div>
            </div>
            {allEventsData.map((events, i) => {
              return (
                <div className="col-lg-6 p-3">
                  <div className="event-card ">
                    <img
                      alt={events.fld_cover_image_alt}
                      className="img-fluid"
                      src={events.fld_cover_image}
                    />
                    <div className="event-right-content">
                      <span>
                        {moment(events.fld_start_date).format("DD-MM-YYYY")} to{" "}
                        {moment(events.fld_end_date).format("DD-MM-YYYY")}
                      </span>
                      <h4>
                        <a
                          href={`/event-detail/${events.fld_event_name.replace(
                            / /g,
                            "-"
                          )}/${events.fld_id}`}
                          alt="Event "
                          aria-label="Event "
                        >
                          {events.fld_event_name}
                        </a>
                      </h4>
                      <p className="event-location">
                        {events.fld_venue} <br /> {events.fld_location}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import "./AwardPage.css";
import Navbar from "../../components/Navbar/Navbar";
export default function LakshmiIyerScholarship() {
  return (
    <>
      <section
        id="award-inner-section"
        className="award-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Awards & Scholarships</li>
                <li>Lakshmi Iyer scholarship</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Lakshmi Iyer scholarship
                </h1>
              </div>
              <p>
                The Lakshmi Iyer scholarship has been instituted by the family
                of Late N.A. Lakshmi and Late H.P.S. Iyer for supporting
                visually impaired students pursuing higher education. Every year
                NAB Delhi management will select meritorious visually impaired
                students from weaker sections of the society and support them
                financially through Lakshmi Iyer scholarship for pursuing higher
                education.
              </p>
              <p>
                The selection of candidates will be done mostly during July to
                September every year. Meritorious visually impaired girls from
                economically weaker sections of the society will be given
                preference in selection.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

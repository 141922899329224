import React from "react";
import "./DonateBtn.css";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from "react-icons/ai";
export default function DanateBtn() {
    return (
        <>
            <div className="donate-btn d-lg-flex">
                {/* <a href="/donate"  aria-label=" Donate Now ">Donate Now</a> */}
                <ul className="d-none d-lg-flex flex-column">
                    <li>
                        <a href="https://wa.me/+918826260010?text=Hello NAB Delhi"  aria-label="Contact us on Whatsapp" target="_blank"><BsWhatsapp /></a>
                    </li>
                    <li><a href="https://www.facebook.com/NABdelhiOnline" target={"_blank"} aria-label=" Facebook "><FaFacebookF /></a></li>
                    <li><a href="https://www.instagram.com/nab.delhi/" target={"_blank"} aria-label=" Instagram "><AiFillInstagram /></a></li>
                    <li><a href="https://www.youtube.com/NABDelhiOnline" target={"_blank"} aria-label=" Youtube "><AiFillYoutube /></a></li>
                    <li><a href="https://twitter.com/nabdelhionline" target={"_blank"} aria-label=" Twitter "><AiOutlineTwitter /></a></li>
                    <li><a href="https://www.linkedin.com/company/nabdelhi/" target={"_blank"} aria-label=" Linkedin "><FaLinkedinIn /></a></li>
                </ul>
            </div>
        </>
    );
}
import React from "react";
import Navbar from "../../components/Navbar/Navbar";

export default function Disclaimer() {
    
    return (
        <>
            <section id="Disclaimer-inner-section" className="Disclaimer-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About Us</li>
                                <li>Disclaimer</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5 pb-3 about-organisation">
                    <div className="row">
                        <div className="col-12">
                            <div className="main-heading text-center pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Disclaimer
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    ???
                                </p> */}
                            </div>
                            <p>
                                    NAB Delhi is proud to partner with various companies and NGOs and to have been granted permission to use their logos. However, please note that none of our partner companies sponsor or endorse NAB Delhi or any of our products or services. The use of partner company logos is solely for the purpose of recognizing our partnership with them.
                                </p>
                                <p>
                                    It is important to note that the logos displayed on our website and marketing materials belong solely to their respective owners. We do not claim any ownership over these logos and acknowledge that they are protected under trademark laws.
                                </p>
                                <p>
                                    NAB Delhi has taken all reasonable care to ensure that the use of partner company logos is consistent with their respective guidelines and standards. However, if you notice any discrepancy or have any concerns regarding the use of a partner company logo, please do not hesitate to contact us at <a href="mailto:info@nabdelhi.in" aria-label=" Email Id">info@nabdelhi.in</a>.
                                </p>
                                <p>
                                    Furthermore, any unauthorized use or misuse of partner company logos is strictly prohibited and may result in legal action. We urge our stakeholders to adhere to the partner company guidelines and standards when using their logos. Any misuse or misrepresentation of a partner company logo may not only harm our relationship with the partner company but may also result in legal liability for NAB Delhi.
                                </p>
                                <p>
                                    We reserve the right to modify or update this disclaimer at any time without prior notice. By using our website and marketing materials, you agree to the terms and conditions of this disclaimer.
                                </p>
                                <p>
                                    If you have any questions regarding the use of partner company logos or any other inquiries, please contact us at <a href="mailto:info@nabdelhi.in" aria-label=" Email Id"> info@nabdelhi.in</a>.
                                </p>
                                <p>
                                    Thank you for your cooperation.
                                </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}